import axios from 'axios';
import { setInterceptors, setInterceptorsWithAuth, setInterceptorsWithRefreshToken } from './config/interceptors';
import { CARELOUNGEURL } from '@/constants';

const useMock = process.env.VUE_APP_USE_MOCK;

function create(url) {
	const instance = axios.create(
		Object.assign({
			baseURL: url,
			headers: {
				'Content-Type': 'application/json',
			},
		}),
	);
	setInterceptors(instance);
	return instance;
}

function createWithAuth(url) {
	const instance = axios.create(
		Object.assign({
			baseURL: url,
			headers: { 'Content-Type': 'application/json' },
		}),
	);
	setInterceptorsWithAuth(instance);
	return instance;
}

function createMultipart(url) {
	const instance = axios.create(
		Object.assign({
			baseURL: url,
			headers: {
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
			},
		}),
	);
	return instance;
}
function createWithAuthAboutRefresh(url) {
	const instance = axios.create(
		Object.assign({
			baseURL: url,
			headers: { 'Content-Type': 'application/json' },
		}),
	);
	setInterceptorsWithRefreshToken(instance);
	return instance;
}

function createWithFile(url) {
	const instance = axios.create(
		Object.assign({
			baseURL: url,
			headers: {},
		}),
	);
	return instance;
}

function createDefault(url) {
	const instance = axios.create(
		Object.assign({
			baseURL: url,
			headers: {},
		}),
	);
	return instance;
}

export const portalAPI = create(`${process.env.VUE_APP_API_URL}`);
export const gatewayAPI = create(`${process.env.VUE_APP_GATEWAY_URL}`);
export const portalAuthAPI = createWithAuth(`${process.env.VUE_APP_API_URL}`);
export const attachAPI = createWithFile(`${process.env.VUE_APP_FILE_API_URL}`);
export const attachMultipartAPI = createMultipart(`${process.env.VUE_APP_FILE_API_URL}`);
export const domsAPI = create(`${process.env.VUE_APP_API_DOMS_SERVER}`);
export const domsAuthRefreshAPI = createWithAuthAboutRefresh(`${process.env.VUE_APP_API_DOMS_SERVER}`);
export const paymentAPI = create(`${process.env.VUE_APP_API_PAY_SERVER}`);

export const portalCareloungeAPI = createDefault(window.__env.portalCareLounge);
export const menualCareloungeAPI = createDefault(window.__env.menualCareLounge);
export const accountCareloungeAPI = createDefault(window.__env.accountCareLounge);
