import { portalAPI, domsAPI, portalAuthAPI } from './index';

// todo: 계약조회 API, DOMS 해당 API이 오픈되어있지 않음.
export const getContractInfoByCode = (code) => {
	return domsAPI.get(`/paysystem/contract/${code}`);
};

export const createNewOrderInfo = (data) => {
	return portalAuthAPI.post(`/order`, data);
};

// todo: 주문적용 API, doms의 해당 API 존재하지 않음, PUT /paysystem/order/apply/{id} 존재하지만 public으로 변경 해야됨.
export const applyNewOrderInfo = (data) => {
	domsAPI.put(`/public/order/apply/${data.id}`);
};

// todo : api check
// doms - 수정 불가.
export const sendNewOrderEstimate = (data) => {
	return domsAPI.post(`/public/order/estimate`, data);
};

// 일단 pay, 용도에 따라 portal로 변경
export const confirmAdminId = (data) => {
	return domsAPI.get(`/addon/checkadmin`, { params: data });
};

// 일단 pay, 용도에 따라 portal로 변경
export const getMembershipPaymentDetail = (membershipId) => {
	return portalAuthAPI.get(`/membership/${membershipId}/payment`);
};

// 일단 pay, 용도에 따라 portal로 변경
export const getMembershipBaseChannelChanged = async (data) => {
	return await portalAuthAPI.get(`/membership/${data.id}/base-channel/${data.baseChannelCode}`);
};

export const getOrderHistories = async (data) => {
	return await portalAuthAPI.get(`/order/histories?page=${data.page}&size=${data.size}`);
};

export const calculateItemPrice = (data) => {
	return domsAPI.post(`/public/order/calculate/item-price`, data);
};

export const getConversionMembership = async (data) => {
	return await domsAPI.get(`/paysystem/membership/conversion/${data.membershipId}/base-channel/${data.baseChannelId}`);
};
