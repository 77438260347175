import Vue from 'vue';
import Vuex from 'vuex';
import login from './modules/login';
import common from './modules/common';
import apply from './modules/apply';
import order from './modules/order';
import addExtension from './modules/addExtension';
import user from './modules/user';
import createPersistedState from 'vuex-persistedstate';
import createLogger from 'vuex/dist/logger';
import { IS_DEV } from '@/constants';

Vue.use(Vuex);

const plugins = [];
if (IS_DEV && process.env.VUE_APP_USE_VUEX_LOGGER === 'true') plugins.push(createLogger());

export default new Vuex.Store({
	modules: {
		common,
		apply,
		login,
		order,
		addExtension,
		user,
	},
	plugins: [
		...plugins,
		createPersistedState({
			paths: ['apply', 'addExtension'],
			reducer(val) {
				if (!val.addExtension.isInOrderProcess) {
					return {};
				}
				return { apply: val.apply, addExtension: val.addExtension };
			},
		}),
	],
});
