import { checkBusinessLicenseNumber } from '@/api/common';

const initState = {
	pageInfo: {
		type: '',
		step: '',
	},
};
export default {
	state: { ...initState },
	actions: {
		async checkBusinessLicenseNumber({ commit }, params) {
			const { data } = await checkBusinessLicenseNumber(params);
			return data;
		},
	},
	mutations: {
		INITIALIZE_COMMON(state, value) {
			const keys = Object.keys(initState);
			keys.map((key) => {
				state[key] = initState[key];
			});
		},
		SET_CURRENT_STEP(state, payload) {
			state.pageInfo.type = payload.type;
			state.pageInfo.step = payload.step;
		},
	},
	getters: {
		getStepClassAttr: (state) => (targetStep) => {
			if (state.pageInfo.step === targetStep) {
				return 'on';
			} else if (state.pageInfo.step > targetStep) {
				return 'complete';
			}
		},

		isFirstPage: (state) => {
			return state.pageInfo.step === 'step1';
		},
	},
};
