import { _ } from 'core-js';
import { domsAPI, domsAuthRefreshAPI, gatewayAPI, portalAPI, portalAuthAPI } from './index';

export const login = (loginData) => {
	return domsAPI.post('/public/login', JSON.stringify(loginData));
};

export const logout = (data, config) => {
	domsAuthRefreshAPI.post('/public/logout', data, config);
};

export const initPassword = async (data) => {
	return await domsAPI.post('/public/initPassword', JSON.stringify(data));
};

export const refreshToken = async (data, config) => {
	return await domsAPI.post('/public/refreshToken', JSON.stringify(data), config);
};

export const rejectUser = async (data, config) => {
	const response = await portalAuthAPI.delete('/auth/reject');
	sessionStorage.removeItem('authToken');
	return response;
};

export const findId = async (data, config) => {
	return await portalAPI.post('/auth/id', JSON.stringify(data), config);
};

export const duplicationEmail = async (email, config) => {
	return await portalAPI.get(`/auth/id?email=${email}`, config);
};

export const duplicationLoginId = async (loginId, config) => {
	return await portalAPI.get(`/auth/id?loginId=${loginId}`, config);
};

export const resetPassword = async (data, config) => {
	return await portalAPI.post('/auth/password', JSON.stringify(data), config);
};

export const unlockAccount = async (data, config) => {
	return await portalAPI.post('/auth/unlock', JSON.stringify(data), config);
};

export const reissue = async (data, config) => {
	return await portalAPI.post('/auth/reissue', JSON.stringify(data), config);
};

export const signIn = async (data, config) => {
	const response = await portalAPI.post('/auth/signin', JSON.stringify(data), config);
	if (!_.isEmpty(response.headers.authorization)) {
		sessionStorage.setItem('authToken', response.headers.authorization);
	}
	return response;
};

export const signInConfirm = async (data, config) => {
	const response = await portalAPI.post('/auth/signin/confirm', JSON.stringify(data), config);
	sessionStorage.setItem('authToken', response.headers.authorization);
	return response;
};

export const signup = async (data, config) => {
	return await portalAPI.post('/auth/signup', JSON.stringify(data), config);
};

export const signupSns = async (data, config) => {
	return await portalAPI.post('/auth/signup/sns', JSON.stringify(data), config);
};

export const signupCustomer = async (data, config) => {
	return await portalAPI.post('/auth/signup/customer', JSON.stringify(data), config);
};

export const signupSnsWithLink = async (data, config) => {
	return await portalAPI.post('/auth/signup/sns/link', JSON.stringify(data), config);
};

export const signupNormalWithLink = async (data, config) => {
	return await portalAPI.post('/auth/signup/normal/link', JSON.stringify(data), config);
};

export const signOut = async (data, config) => {
	return await portalAuthAPI.post('/auth/signout');
};

export const sendAccountTwoFactor = async (data, config) => {
	return await portalAPI.put('/auth/signin/retry', JSON.stringify(data), config);
};

export const changePassword = async (data, config) => {
	return await portalAuthAPI.put('/auth/password', JSON.stringify(data), config);
};

export const InitPassword = async (data, config) => {
	return await portalAuthAPI.post(`/auth/password/init`, JSON.stringify(data), config);
};

export const forceChangePassword = async (data, config) => {
	let response = await portalAPI.put(`/auth/password/force`, JSON.stringify(data), config);
	if (!_.isEmpty(response.headers.authorization)) {
		sessionStorage.setItem('authToken', response.headers.authorization);
	}
	return response;
};

export const checkOldPassword = async (data, config) => {
	return await portalAuthAPI.post(`/auth/password/log`, JSON.stringify(data), config);
};

export const checkPasswordLog = async (data, config) => {
	return await portalAuthAPI.post(`/auth/password/logs`, JSON.stringify(data), config);
};

export const retryEmail = async (data, config) => {
	return await portalAPI.put('/auth/signup/retry', JSON.stringify(data), config);
};

export const kmcSignup = async (codeType) => {
	const redirectUrl = '/identityVerificationSuccess';
	return await portalAPI.get(`/kmc/encrypt/${codeType}?redirectUrl=` + encodeURIComponent(redirectUrl));
};

export const loginByConcurrentAccess = async () => {
	const token = sessionStorage.getItem('authToken');
	return await gatewayAPI.post(`/auth/concurrent`, token);
};

export const isSignupCustomer = async (data) => {
	return await portalAPI.post('/auth/link/is-signup/customer', data);
};
