<template>
	<div class="toast caution" style="">
		<button class="icon" @click="closeModal"><i class="ic-16 ic-close"></i> 닫기</button>
		<div class="lay_header">
			<h1>{{ toastText }}</h1>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		toastText: {
			type: String,
		},
	},
	methods: {
		closeModal() {
			this.$emit('close');
		},
	},
};
</script>

<style></style>
