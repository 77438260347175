import Vue from 'vue';
import store from '@/store/index';
import { INVALID_PASSWORD_CONTENT, INVALID_PASSWORD_LENGTH_CHARACTER, VALID_PASSWORD } from '@/temlplates';

export const isEmpty = (value) => {
	if (value === null) return true;
	if (typeof value === 'undefined') return true;
	if (typeof value === 'string' && value === '') return true;
	if (typeof value === 'string' && value.trim() === '') return true;
	if (Array.isArray(value) && value.length < 1) return true;
	if (
		typeof value === 'object' &&
		value.constructor.name === 'Object' &&
		Object.keys(value).length < 1 &&
		Object.getOwnPropertyNames(value) < 1
	)
		return true;
	return !!(typeof value === 'object' && value.constructor.name === 'String' && Object.keys(value).length < 1);
};

export const byteToGigaByte = (quota) => {
	return (quota / 1024 / 1024 / 1024).toFixed(1);
};

export const gigaByteToByte = (quota) => {
	return parseInt(quota * 1024 * 1024 * 1024);
};

/* LocalDateTime 형태로 반환 (현재시간) */
export const currentLocalDateTime = () => {
	return Vue.prototype.$moment().format('YYYY-MM-DDTHH:mm:ss');
};

export const isDomsAdmin = () => {
	const loginId = sessionStorage.getItem('loginId');
	return !!(loginId && loginId.includes('DOMS_'));
};

export const isOrderMultiCompany = () => {
	const options = store.getters.getBasicItemSelected[0].optionItemSelected;
	const multiCompany = options.filter(
		(option) => (option.itemType === 'UNABLE_CHANGE_QUANTITY') & (option.name === '멀티컴퍼니'),
	);
	return multiCompany.length > 0;
};

export const hasElement = (parent, targetElement) => {
	if (!parent || !targetElement) return false;
	let result = false;

	const _hasElement = (currentElement) => {
		for (const element of currentElement.childNodes) {
			if (element === targetElement) {
				result = true;
				break;
			} else {
				_hasElement(element);
			}
		}
	};
	_hasElement(parent);

	return result;
};

export const masking = {
	isNull(str) {
		if (typeof str == 'undefined' || str == null || str === '') {
			return true;
		} else {
			return false;
		}
	},
	email(str) {
		let originStr = str;
		if (this.isNull(originStr)) return originStr;
		let emailStr = originStr.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
		let strLength;

		if (this.isNull(emailStr)) {
			return originStr;
		} else {
			strLength = emailStr.toString().split('@')[0].length - 4;
			return originStr.toString().replace(new RegExp('.(?=.{0,' + strLength + '}@)', 'g'), '*');
		}
	},
	username(str) {
		let originStr = str;

		if (this.isNull(originStr)) {
			return originStr;
		}

		if (originStr.length < 3) {
			return originStr.replace(/(?<=.{1})./gi, '*');
		} else {
			let nameList = originStr.toString().split('');
			nameList.forEach((name, i) => {
				if (i === 0 || i === nameList.length - 1) {
					return;
				}
				nameList[i] = '*';
			});
			return nameList.join('');
		}
	},
};

export const password_validation = {
	checkLengthAndCharacter(pwd) {
		const lenAndChar = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,16}$/g;
		if (!lenAndChar.test(pwd)) {
			return false;
		}
		return true;
	},
	checkIdentityCharacters(pwd, phoneNumber, birthday, email, loginId) {
		// let identityInfoTxt =
		// 	('' + phoneNumber).substring(3, 7) +
		// 	'|' +
		// 	('' + phoneNumber).substring(7, 11) +
		// 	'|' +
		// 	('' + birthday).substring(0, 4) +
		// 	'|' +
		// 	('' + birthday).substring(4, 8) +
		// 	'|' +
		// 	('' + loginId);
		let identityInfoTxt = '';
		identityInfoTxt = phoneNumber
			? identityInfoTxt + ('' + phoneNumber).substring(3, 7) + '|' + ('' + phoneNumber).substring(7, 11) + '|'
			: identityInfoTxt;

		identityInfoTxt = birthday
			? identityInfoTxt + ('' + birthday).substring(0, 4) + '|' + ('' + birthday).substring(4, 8) + '|'
			: identityInfoTxt;

		identityInfoTxt = loginId ? identityInfoTxt + loginId : identityInfoTxt;
		if (('' + email).indexOf('@') !== -1)
			identityInfoTxt =
				!identityInfoTxt || identityInfoTxt.endsWith('|')
					? identityInfoTxt.concat(('' + email).split('@')[0])
					: identityInfoTxt.concat('|').concat(('' + email).split('@')[0]);

		identityInfoTxt = identityInfoTxt.endsWith('|')
			? identityInfoTxt.substring(0, identityInfoTxt.length - 1)
			: identityInfoTxt;
		if (!identityInfoTxt) {
			return true;
		}
		let identityInfo = new RegExp(identityInfoTxt);
		if (identityInfo.test(pwd)) {
			return false;
		}
		return true;
	},
	checkRepeatedCharacters(pwd) {
		if (/(\w)\1\1/.test(pwd) || /(\d)\1\1/.test(pwd)) {
			return false;
		}
		return true;
	},
	checkConsecutiveCharacters(str) {
		let preChar = 0;
		let preDiff = 0;
		let diff = 0;
		let diffEqualPreDiffCnt = 0;
		let limitLen = 3;
		for (let i = 0; i < str.length; i++) {
			let nowChar = str.charCodeAt(i);
			diff = preChar - nowChar;
			if (this.isConsecutive(i, diff, preDiff, diffEqualPreDiffCnt, limitLen)) {
				return false;
			}
			preDiff = diff;
			preChar = nowChar;
		}
		return true;
	},
	isConsecutive(i, diff, preDiff, diffEqualPreDiffCnt, limitLen) {
		return (
			i > 0 && diff > -2 && diff < 2 && this.checkConsecutiveLengthLimit(diffEqualPreDiffCnt, diff, preDiff, limitLen)
		);
	},
	isSameDiff(diff, preDiff) {
		return diff === preDiff;
	},
	checkConsecutiveLengthLimit(diffEqualPreDiffCnt, diff, preDiff, limitLen) {
		return (diffEqualPreDiffCnt = this.isSameDiff(diff, preDiff) ? diffEqualPreDiffCnt + 1 : 0) > limitLen - 3;
	},
};

export const getLastReadNoticeId = () => {
	return parseInt(localStorage.getItem('lastReadNoticeId') || 0);
};

export const isMobileDevice = () => {
	const agent = navigator.userAgent.toLowerCase();
	if (agent.indexOf('iphone|ipad|android|blackberry|windows phone|ipod') > -1) {
		return true;
	}
	return false;
};
