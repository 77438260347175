import { portalAPI, portalAuthAPI } from './index';

export const getCustomerInfo = async (data, config) => {
	return await portalAuthAPI.get(`/customer`);
};
export const setCustomerInfo = async (data, config) => {
	return await portalAuthAPI.post(`/customer`, data);
};

export const getCustomerHistoryInfo = async (data, config) => {
	return await portalAuthAPI.get(`/customer/history?page=${data.page}&size=${data.size}`);
};

export const getCustomerUserInfo = async (data, config) => {
	return await portalAuthAPI.get(`/customer/users?page=${data.page}&size=${data.size}`);
};

export const updateCustomerUserRole = async (data, config) => {
	return await portalAuthAPI.put(`/customer/users`, data);
};

export const applyCustomerUser = async (data, config) => {
	return await portalAuthAPI.post(`/customer/users/apply`, data);
};

export const getCustomerUserHistoryInfo = async (data, config) => {
	return await portalAuthAPI.get(`/customer/users/history?page=${data.page}&size=${data.size}`);
};

export const linkCustomerWithAccount = async (data) => {
	return await portalAuthAPI.put(`/customer/link`, data);
};

export const getTerms = async () => {
	return await portalAPI.get('/public/terms');
};

export const getTermDetail = async (termsId) => {
	return await portalAPI.get(`/public/terms/${termsId}`);
};
