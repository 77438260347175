import { portalAPI } from '@/api/index';
import router from '@/router';
import store from '@/store/index';
import { getAuthFromCookie, getUserFromCookie } from '@/utils/cookies';
import { refreshToken } from '@/api/login';
import { alert } from '@/plugins/custom/modules/dialog';

function setInterceptors(instance) {
	instance.interceptors.request.use(
		(config) => {
			config.withCredentials = true;
			return config;
		},
		(error) => Promise.reject(error.response),
	);
	instance.interceptors.response.use(
		(response) => {
			if (response.data.error) {
				if (response.data.status == '400' || response.data.status == '401') {
					console.log(response.data.errorMessage, response.data.errorDescription);
					window.alert(response.data.errorMessage, response.data.errorDescription);
				} else if (response.data.status == '500') {
					const defaultErrorMsg =
						'정의되지 않은 서버 에러로, 서버관리자의 확인이 필요한 에러입니다. 관리자에게 문의해주세요.';
					window.alert(
						'서버 에러 발생',
						response.data.errorDescription ? response.data.errorDescription : defaultErrorMsg,
					);
				} else {
					const errorMsg = response.data.status + ' 오류가 발생했습니다. 관리자에게 문의해주세요.';
					window.alert('에러 발생', errorMsg);
				}
			}
			return response;
		},
		async (error) => {
			const response = error.response;

			if (response.data.status == '401' && response.data.errorCode == 'GW_ERR002') {
				router.push('/force/logout');
				return;
			}
			if (response.status == '400' || response.status == '401') {
				if (!response.data.errorCode) {
					window.alert('잘못된 요청입니다.');
				}
			}
			await Promise.reject(error.response);
		},
	);
	return instance;
}

function setInterceptorsWithAuth(instance) {
	instance.interceptors.request.use(
		(config) => {
			config.headers.Authorization = sessionStorage.getItem('authToken');
			config.withCredentials = true;
			return config;
		},
		(error) => Promise.reject(error.response),
	);
	instance.interceptors.response.use(
		(response) => {
			if (!response.data.error) {
				return response;
			}
			const data = response.data;
			if (data.status == '400' || data.status == '401') {
				alert(data.errorMessage, data.errorDescription);
			} else if (data.status == '500') {
				alert(
					'서버 에러 발생',
					'정의되지 않은 서버 에러로, 서버관리자의 확인이 필요한 에러입니다. 관리자에게 문의해주세요.',
				);
			} else if (data.status == '409' || data.status == '406') {
				store.commit('SET_INIT_USER');
				const reason = data.status == '409' ? 'concurrent' : 'expireToken';
				router.push({ name: 'Login', params: { reason: reason } });
			}
			return response;
		},
		async (error) => {
			const errorData = error.response.data;
			if (errorData && errorData.status == '401' && errorData.errorCode == 'GW_ERR002') {
				router.push('/force/logout');
				return;
			}
			const errorCode = error.response.status;
			if (errorCode === 406) {
				try {
					const apiConfig = {
						headers: {
							Accept: 'application/json, text/plain, */*',
							Authorization: getRefreshToken(),
						},
					};

					const response = await refreshToken(getUserFromCookie(), apiConfig);
					store.commit('SET_ACCESS_TOKEN', response.data.data.token);
					const errorConfig = error.response.config;
					const config = { ...errorConfig };
					config.headers = {
						Authorization: getAccessToken(),
					};
					// axios 자체가 header 세팅이 안되는 버그가 있는것같다.
					// headers 의 'Content-Type': 'application/json' 이 아닌 경우가 생기면
					// 수정이 필요하다.
					const result = await portalAPI(config);
					return result;
				} catch (e) {
					store.commit('SET_INIT_USER');
					router.push({ name: 'Login', params: { reason: 'expireToken' } });
				}
			}
			await Promise.reject(error.response);
		},
	);
	return instance;
}

function setInterceptorsWithRefreshToken(instance) {
	instance.interceptors.request.use(
		(config) => {
			config.headers.Authorization = getRefreshToken();
			return config;
		},
		(error) => Promise.reject(error.response),
	);
	instance.interceptors.response.use(
		(response) => {
			return response;
		},
		async (error) => {
			await Promise.reject(error.response);
		},
	);
	return instance;
}

function getRefreshToken() {
	const prefix = 'Bearer ';
	return prefix + getAuthFromCookie().refresh_token;
}

function getAccessToken() {
	const prefix = 'Bearer ';
	return prefix + getAuthFromCookie().access_token;
}

export { setInterceptorsWithAuth, setInterceptorsWithRefreshToken, setInterceptors };
