<template>
	<router-view id="app" />
</template>

<script>
export default {};
</script>
<style>
/* @import './assets/css/doms_common.css'; */
</style>
