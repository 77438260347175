import _ from 'lodash';
import { deleteCookie, getAuthFromCookie, saveAccessTokenToCookie } from '@/utils/cookies.js';
import {
	changePassword,
	forceChangePassword,
	InitPassword,
	isSignupCustomer,
	loginByConcurrentAccess,
	signIn,
	signOut,
	unlockAccount,
} from '@/api/login';
import { initialMenus } from '@/components/common/sideMenuData';
import {
	deleteProfile,
	fetchCurrentUser,
	fetchUserTerms,
	newSnsLogin,
	updateProfile,
	updateUserEmail,
	updateUserExtra,
	updateUserIdentity,
	updateUserTerms,
	updateUserTwoFactor,
	updateCustomerManagerUserIdentity,
} from '@/api/user';
import { Promise } from 'es6-promise';

// state
const initState = {
	accessMenus: initialMenus,
	roles: [],
	ci: '',
	phoneNo: '',
	birthday: '',
	token: '',
	currentUser: {},
	agreementList: [],
	isLockedAccount: false,
	linkCodes: {},
};
export default {
	state: { ...initState },
	getters: {
		userToken() {
			return getAuthFromCookie().access_token;
		},
		isCurrentUser: (state) => {
			return !_.isEmpty(state.currentUser);
		},
		isMemberShipUser: (state) => {
			/*
            accountLevelType
             - LEVEL1: 일반회원
             - LEVEL2: 멤버십 회원
             */
			return state.currentUser?.levelType !== 'LEVEL1';
		},
		isSuperAdmin: (state) => {
			return state.currentUser?.accountRoleType === 'SUPER ADMIN';
		},
		isAdmin: (state) => {
			return state.currentUser?.accountRoleType === 'ADMIN';
		},
		isNormal: (state) => {
			return state.currentUser?.accountRoleType === 'NORMAL';
		},
		hasAuth: (state) => {
			return !_.isEmpty(state.currentUser);
		},
		isTwoFactor: (state) => {
			return state.currentUser.accountTwoFactorType !== 'NONE';
		},
		isLockedAccount(state) {
			return state.isLockedAccount;
		},
		userProfileUrl(state) {
			return state.currentUser.profileUrl;
		},
		getBirth(state) {
			return (
				state.currentUser.birthDay.substring(0, 4) +
				'.' +
				state.currentUser.birthDay.substring(4, 6) +
				'.' +
				state.currentUser.birthDay.substring(6, 8)
			);
		},
		getPhone(state) {
			return (
				state.currentUser.phoneNumber.substring(0, 3) +
				'-' +
				state.currentUser.phoneNumber.substring(3, 7) +
				'-' +
				state.currentUser.phoneNumber.substring(7, 11)
			);
		},
		getLoginId(state) {
			return state.currentUser.loginId;
		},
		getRequiredTermsList(state) {
			return state.agreementList.filter((agreement) => agreement.required);
		},
		getNotRequiredTermsList(state) {
			return state.agreementList.filter((agreement) => !agreement.required);
		},
		hasPassword(state) {
			return state.currentUser.hasPassword;
		},
		getExtra(state) {
			return {
				rank: state.currentUser.rank,
				tel: state.currentUser.tel,
			};
		},
		hasProfile(state) {
			return !!state.currentUser.profileUrl;
		},
		getDaouofficeCode(state) {
			return state.linkCodes.daouofficeCode;
		},
		getInviteCode(state) {
			return state.linkCodes.inviteCode;
		},
		getLinkCodes(state) {
			return state.linkCodes;
		},
		hasNormalLinkCode(state) {
			return state.linkCodes.daouofficeCode || state.linkCodes.inviteCode;
		},
	},
	actions: {
		async login({ commit }, param) {
			let response = await signIn(param);
			commit('SET_CURRENT_USER', response.data);
			return response;
			// const { data } = await login(param);
			// const responseData = data.data;
			// if (responseData == undefined) {
			// 	throw data;
			// }
			// if (!responseData.forceChangePassword && !responseData.requireBounceOff) {
			// 	commit('SAVE_TOKEN', {
			// 		loginInfo: responseData,
			// 		checkSaveId: param.checkSaveId,
			// 	});
			// }
			// return responseData;
		},
		async checkAuth({ commit, getters }, param) {
			const token = sessionStorage.getItem('authToken');
			let response = Promise.resolve();
			if (!getters.hasAuth && token) {
				try {
					response = await fetchCurrentUser();
					commit('SET_CURRENT_USER', response.data);
				} catch (e) {
					commit('SET_CURRENT_USER', {});
				}
			}
			return response;
		},
		async fetchUserProfile({ commit }, param) {
			const token = sessionStorage.getItem('authToken');
			if (token) {
				let response = await fetchCurrentUser()
					.then(async (response) => {
						commit('SET_CURRENT_USER', response.data);
					})
					.catch((e) => {
						commit('SET_CURRENT_USER', {});
					});
				response = await fetchUserTerms()
					.then((response) => {
						commit('SET_TERMS', response.data);
					})
					.catch((e) => {
						commit('SET_TERMS', []);
					});
			}
		},
		async logout({ commit }, param) {
			const response = await signOut(param);
			commit('LOGOUT');
			return response;
		},
		async updateUserIdentity({ commit }, param) {
			const token = sessionStorage.getItem('authToken');
			let response = Promise.resolve();
			if (token) {
				response = await updateUserIdentity(param);
				commit('UPDATE_CURRENT_USER_IDENTITY', response.data);
			}
			return response;
		},
		async updateCustomerManagerIdentity({ commit }, param) {
			const token = sessionStorage.getItem('authToken');
			let response = Promise.resolve();
			if (token) {
				response = await updateCustomerManagerUserIdentity(param);
				commit('UPDATE_CURRENT_USER_IDENTITY', response.data);
			}
			return response;
		},
		async updateUserTwoFactor({ commit }, param) {
			const token = sessionStorage.getItem('authToken');
			let response = Promise.resolve();
			if (token) {
				response = await updateUserTwoFactor(param);
				commit('UPDATE_CURRENT_USER_TWOFACTOR', response.data);
			}
			return response;
		},
		async updateUserExtra({ commit }, param) {
			const token = sessionStorage.getItem('authToken');
			let response = Promise.resolve();
			if (token) {
				response = await updateUserExtra(param);
				commit('UPDATE_CURRENT_USER_EXTRA', response.data);
			}
			return response;
		},
		async updateUserEmail({ commit }, param) {
			let response = await updateUserEmail(param);
			commit('UPDATE_CURRENT_USER_EMAIL', response.data);
			return response;
		},
		async updateUserTerms({ commit }, param) {
			const token = sessionStorage.getItem('authToken');
			let response = Promise.resolve();
			if (token) {
				response = await updateUserTerms(param);
				commit('UPDATE_CURRENT_USER_TERMS', response.data);
			}
			return response;
		},
		// @Author: jjm
		async fetchUnlock({ commit }, param) {
			const response = await unlockAccount(param);
			commit('SET_IS_LOCKED_ACCOUNT', false);
			return response;
		},
		async newSnsLogin({ commit }, param) {
			const token = sessionStorage.getItem('authToken');
			let response = Promise.resolve();
			if (token) {
				response = await newSnsLogin(param);
				commit('SET_CURRENT_USER', response.data);
			}
			return response;
		},
		async updateUserProfileUrl({ commit }, param) {
			const token = sessionStorage.getItem('authToken');
			if (token) {
				await updateProfile(param);
				commit('SET_CURRENT_USER_PROFILE', param);
			}
			return Promise.resolve();
		},
		async deleteUserProfileUrl({ commit }, param) {
			const token = sessionStorage.getItem('authToken');
			let response = Promise.resolve();
			if (token) {
				response = await deleteProfile();
				commit('DELETE_CURRENT_USER_PROFILE');
			}
			return response;
		},
		async initUserPassword({ commit }, param) {
			const token = sessionStorage.getItem('authToken');
			let response = Promise.resolve();
			response = await InitPassword(param);
			commit('HAS_CURRENT_USER_PASSWORD');
			return response;
		},
		async changeUserPassword({ commit }, param) {
			const token = sessionStorage.getItem('authToken');
			let response = Promise.resolve();
			response = await changePassword(param);
			commit('HAS_CURRENT_USER_PASSWORD');
			return response;
		},
		async loginConcurrentAccess({ commit }) {
			const { data } = await loginByConcurrentAccess();
			return data;
		},
		async loginAfterForcedPasswordChange({ commit }, param) {
			let response = Promise.resolve();
			try {
				response = forceChangePassword(param);
			} catch (e) {
				console.log(e);
			}
			return response;
		},
		async getIsSignupCustomer({ commit }, param) {
			try {
				const response = await isSignupCustomer(param);
				return response.data;
			} catch (response) {
				console.error(response.data.errorMessage);
			}
		},
	},
	mutations: {
		INITIALIZE_LOGIN(state, value) {
			const keys = Object.keys(initState);
			keys.map((key) => {
				state[key] = initState[key];
			});
		},
		// using in interceptor
		SET_ACCESS_TOKEN(state, token) {
			saveAccessTokenToCookie(token);
		},
		// using in interceptor
		SET_INIT_USER(state) {
			deleteCookie('doms_access_token');
			deleteCookie('doms_refresh_token');
			deleteCookie('doms_user');
		},
		// using in interceptor
		SET_IS_LOCKED_ACCOUNT(state, param) {
			state.isLockedAccount = param;
		},
		SET_CURRENT_USER(state, data) {
			state.currentUser = Object.assign({}, data);
		},
		LOGOUT(state, data) {
			state.currentUser = {};
		},
		SET_CURRENT_USER_PROFILE(state, data) {
			state.currentUser.profileUrl = data;
		},
		SET_TERMS(state, data) {
			state.agreementList = data;
		},
		DELETE_CURRENT_USER_PROFILE(state) {
			state.currentUser.profileUrl = '';
		},
		UPDATE_CURRENT_USER_IDENTITY(state, data) {
			state.currentUser.name = data.name;
			state.currentUser.birthDay = data.birthDay;
			state.currentUser.phoneNumber = data.phoneNumber;
		},
		UPDATE_CURRENT_USER_TERMS(state, data) {
			state.agreementList = data;
		},
		UPDATE_CURRENT_USER_TWOFACTOR(state, data) {
			state.currentUser.accountTwoFactorType = data.accountTwoFactorType;
			state.currentUser.twoFactorUpdateAt = data.twoFactorUpdateAt;
		},
		UPDATE_CURRENT_USER_EXTRA(state, data) {
			state.currentUser.rank = data.rank;
			state.currentUser.tel = data.tel;
		},
		HAS_CURRENT_USER_PASSWORD(state) {
			state.currentUser.hasPassword = true;
		},
		UPDATE_CURRENT_USER_EMAIL(state, data) {
			state.currentUser.email = data.email;
		},
		SET_DAOUOFFICE_CODE(state, code) {
			state.linkCodes.daouofficeCode = code;
		},
		DELETE_DAOUOFFICE_CODE(state) {
			state.linkCodes.daouOfficeCode = '';
		},
		SET_INVITE_CODE(state, code) {
			state.linkCodes.inviteCode = code;
		},
		CLEAR_LINK_CODES(state) {
			state.linkCodes = {};
		},
	},
};
