import {
	getBaseChannelList,
	getAddonChannelList,
	getBaseChannelOptionItemList,
	getAddonChannelOptionItemList,
	getBaseChannelOptionByCode,
	getEndDate,
	getTodayPromotionList,
	checkBaseChannelIsConvertable,
} from '@/api/product';
import {
	getCustomerHistoryInfo,
	getCustomerInfo,
	getCustomerUserInfo,
	setCustomerInfo,
	updateCustomerUserRole,
	getCustomerUserHistoryInfo,
	applyCustomerUser,
	linkCustomerWithAccount,
	getTerms,
} from '@/api/customer';

const initState = {
	currentChargeType: 'PAY',
	startDate: '',
	endDate: '',
	totQuota: 0,
	usePeriod: 0, // 신규주문 사용기간
	basicItemSelected: [], // 기본서비스 only 1개
	addonChannelSelectedList: [], // 선택한 부가서비스 리스트 多수 가능
	/*서비스신청페이지에서 기입한 내용들 */
	companyInfo: {}, // 회사 정보
	orderTempCompanyInfo: {}, // 주문시 회사정보 임시 저장소.
	companyManagerInfo: {}, // 담당자 정보
	companySiteInfo: {}, // 개설 정보
	companyAdditionalInfo: {}, // 추가 정보
	termsAgreementInfo: null, // 약관 동의
	/* 견적서 기입 내용들 */
	estimateAllCompanyInfo: {
		companyInfo: {}, // 회사 정보
		companyManagerInfo: {}, // 담당자 정보
	},
	estimateTermsAgreementInfo: null, // 약관 동의
	payMethodInfo: {}, // 결제 수단
	businessRegistration: [], // 사업자 등록증
	agreeTerm: false, // 약관 동의 여부 false/true (modal창 데이터 통신에 사용)
	accountAgreeTerm: false, //경리회계 약관동의
	promotionDiscount: { use: false },
	promotionAdvertisemnetStore: { use: false },
	promotionAdvertisemnetApply: { use: false },
	topBanner: [],
	homeBanner: {},
	customerHistoryInfo: { page: 0, size: 10, historyList: [], total: 0 },
	customerUserInfo: { page: 0, size: 10, userList: [], total: 0, typeList: [] },
	customerUserHistoryInfo: { page: 0, size: 10, historyList: [], total: 0 },
	customerTermsList: [],
	selectedCardOption: '',
	conversionProduct: {}, // 신요금제 전환 대상 상품 정보
	isExtensionOrAddExtensionOrderWithConversionProduct: false,
};
export default {
	state: { ...initState },
	getters: {
		getCurrentChargeType(state) {
			return state.currentChargeType;
		},
		getEstimateAllCompanyInfo(state) {
			return state.estimateAllCompanyInfo;
		},
		getEstimateTermsAgreementInfo(state) {
			return state.estimateTermsAgreementInfo;
		},
		getAgreeTerm(state) {
			return state.agreeTerm;
		},
		getAccountAgreeTerm(state) {
			return state.accountAgreeTerm;
		},
		getTotQuota(state) {
			return state.totQuota;
		},
		getBusinessRegistration(state) {
			return state.businessRegistration ?? '';
		},
		getStartDate(state) {
			return state.startDate;
		},
		getEndDate(state) {
			return state.endDate;
		},
		getUsePeriod(state) {
			return state.usePeriod;
		},
		getBasicItemSelected(state) {
			//선택된 기본서비스아이템 return
			return state.basicItemSelected;
		},
		getCardOption(state) {
			return state.selectedCardOption;
		},
		getAddonItemSelected(state) {
			//선택된 부가서비스아이템 return
			return state.addonChannelSelectedList;
		},
		getSummuryPrices(state) {
			// 상품금액, 할인금액, 상품금액-할인금액, 부가세, 총금액(VAT포함) : Display용 - 무료체험은 0원 처리
			let totOriginPrice = 0; // 총 원가
			let totDiscountPrice = 0; //총 할인금액

			if (state.basicItemSelected.length > 0) {
				totOriginPrice += state.basicItemSelected[0].membershipFeeNormalPrice; // 설치비
				totOriginPrice += state.basicItemSelected[0].defaultFeeTotNormalPrice; // 기본료
				totOriginPrice += state.basicItemSelected[0].addUserTotNormalPrice; // 사용자추가금

				for (let i in state.basicItemSelected[0].optionItemSelected) {
					totOriginPrice += state.basicItemSelected[0].optionItemSelected[i].finalPrice;
				} // 기본서비스 옵션 금액

				totDiscountPrice += state.basicItemSelected[0].membershipFeeDiscount; // 기본료할인액
				totDiscountPrice += state.basicItemSelected[0].defaultFeeTotDiscount; // 기본료할인액
				totDiscountPrice += state.basicItemSelected[0].addUserTotDiscount; // 사용자추가할인액
			}
			for (let i in state.addonChannelSelectedList) {
				totOriginPrice += state.addonChannelSelectedList[i].membershipFeeNormalPrice;
				totOriginPrice += state.addonChannelSelectedList[i].defaultFeeTotNormalPrice;
				totOriginPrice += state.addonChannelSelectedList[i].addUserTotNormalPrice;

				for (let j in state.addonChannelSelectedList[i].optionItemSelected) {
					totOriginPrice += state.addonChannelSelectedList[i].optionItemSelected[j].finalPrice;
				}
			} // 부가서비스 금액

			for (let i in state.addonChannelSelectedList) {
				totDiscountPrice += state.addonChannelSelectedList[i].membershipFeeDiscount;
				totDiscountPrice += state.addonChannelSelectedList[i].defaultFeeTotDiscount;
				totDiscountPrice += state.addonChannelSelectedList[i].addUserTotDiscount;
			} // 부가서비스 금액
			let totPayPrice = totOriginPrice + totDiscountPrice; //totDiscountPrice : -값
			totPayPrice = totPayPrice / 10;
			totPayPrice = Math.floor(totPayPrice);
			totPayPrice = totPayPrice * 10;
			let surtaxPrice = totPayPrice * 0.1; // 부가세 10%
			let finalPayPrice = totPayPrice + surtaxPrice;

			return {
				totOriginPrice: totOriginPrice,
				totDiscountPrice: totDiscountPrice,
				totPayPrice: totPayPrice,
				surtaxPrice: surtaxPrice,
				finalPayPrice: finalPayPrice,
			};
		},
		getSummuryOriginPrices(state) {
			// 상품금액, 할인금액, 상품금액-할인금액, 부가세, 총금액(VAT포함) : 견적용 - 무료체험 실제 금액으로 계산
			// 무료체험일 때 backup해놓은 진짜 결제금액
			let totOriginPrice = 0; // 총 원가
			totOriginPrice += state.basicItemSelected[0].membershipFeeNormalPrice; // 설치비
			totOriginPrice += state.basicItemSelected[0].defaultFeeTotNormalPrice; // 기본료
			totOriginPrice += state.basicItemSelected[0].addUserTotNormalPrice; // 사용자추가금

			for (let i in state.addonChannelSelectedList) {
				if (
					state.addonChannelSelectedList[i].freeTrialPolicyModel !== undefined &&
					state.addonChannelSelectedList[i].freeTrialPolicyModel.usePolicy
				) {
					totOriginPrice += state.addonChannelSelectedList[i].membershipFeeNormalPriceReal;
					totOriginPrice += state.addonChannelSelectedList[i].defaultFeeTotNormalPriceReal;
					totOriginPrice += state.addonChannelSelectedList[i].addUserTotNormalPriceReal;
				} else {
					totOriginPrice += state.addonChannelSelectedList[i].membershipFeeNormalPrice;
					totOriginPrice += state.addonChannelSelectedList[i].defaultFeeTotNormalPrice;
					totOriginPrice += state.addonChannelSelectedList[i].addUserTotNormalPrice;
				}

				for (let j in state.addonChannelSelectedList[i].optionItemSelected) {
					totOriginPrice += state.addonChannelSelectedList[i].optionItemSelected[j].finalPriceReal;
				}
			} // 부가서비스 금액

			for (let i in state.basicItemSelected[0].optionItemSelected) {
				totOriginPrice += state.basicItemSelected[0].optionItemSelected[i].finalPrice;
			} // 기본서비스 옵션 금액

			let totDiscountPrice = 0; //총 할인금액
			totDiscountPrice += state.basicItemSelected[0].membershipFeeDiscount; // 기본료할인액
			totDiscountPrice += state.basicItemSelected[0].defaultFeeTotDiscount; // 기본료할인액
			totDiscountPrice += state.basicItemSelected[0].addUserTotDiscount; // 사용자추가할인액
			for (let i in state.addonChannelSelectedList) {
				if (
					state.addonChannelSelectedList[i].freeTrialPolicyModel !== undefined &&
					state.addonChannelSelectedList[i].freeTrialPolicyModel.usePolicy
				) {
					totDiscountPrice += state.addonChannelSelectedList[i].membershipFeeDiscountReal;
					totDiscountPrice += state.addonChannelSelectedList[i].defaultFeeTotDiscountReal;
					totDiscountPrice += state.addonChannelSelectedList[i].addUserTotDiscountReal;
				} else {
					totDiscountPrice += state.addonChannelSelectedList[i].membershipFeeDiscount;
					totDiscountPrice += state.addonChannelSelectedList[i].defaultFeeTotDiscount;
					totDiscountPrice += state.addonChannelSelectedList[i].addUserTotDiscount;
				}
			} // 부가서비스 금액
			let totPayPrice = totOriginPrice + totDiscountPrice; //totDiscountPrice : -값
			let surtaxPrice = totPayPrice * 0.1; // 부가세 10%
			let finalPayPrice = totPayPrice + surtaxPrice;

			return {
				totOriginPrice: totOriginPrice,
				totDiscountPrice: totDiscountPrice,
				totPayPrice: totPayPrice,
				surtaxPrice: surtaxPrice,
				finalPayPrice: finalPayPrice,
			};
		},
		getAllCompanyInfo(state) {
			// 신청 시 기입한 고객정보들 return
			let data = {
				companyInfo: state.companyInfo,
				companyManagerInfo: state.companyManagerInfo,
				companySiteInfo: state.companySiteInfo,
				companyAdditionalInfo: state.companyAdditionalInfo,
			};
			return data;
		},
		getPayMethodInfo(state) {
			return state.payMethodInfo;
		},
		getPromotionDiscount(state) {
			return state.promotionDiscount;
		},
		getPromotionAdvertisementApply(state) {
			return state.promotionAdvertisemnetApply;
		},
		getPromotionAdvertisementStore(state) {
			return state.promotionAdvertisemnetStore;
		},
		getTopBanner(state) {
			return state.topBanner;
		},
		getHomeBanner(state) {
			return state.homeBanner;
		},
		isSelecteFreeTrialItem(state) {
			// 선택한 서비스들 中 무료체험 포함 여부 return
			for (let i in state.addonChannelSelectedList) {
				let item = state.addonChannelSelectedList[i];

				if (item.freeTrialPolicyModel !== undefined) {
					if (item.freeTrialPolicyModel.usePolicy && item.freeTrialPolicyModel.usagePeriod < item.usePeriod) {
						return {
							item,
						};
					}
				}
			}
			return false;
		},
		getOrderTempCompanyInfo(state) {
			return state.orderTempCompanyInfo;
		},
		getCompanyInfo(state) {
			return state.companyInfo;
		},
		getCustomerHistoryInfo(state) {
			return state.customerHistoryInfo;
		},
		getCustomerUserInfo(state) {
			return state.customerUserInfo;
		},
		getCustomerUserHistoryInfo(state) {
			return state.customerUserHistoryInfo;
		},
		getCustomerTermsList(state) {
			return state.customerTermsList;
		},
		getCustomerTermsOfUse(state) {
			return state.customerTermsList.find((terms) => terms.termsType == 'TERMS_OF_USE');
		},
		getCompanyManagerInfo(state) {
			return state.companyManagerInfo;
		},
		getConversionProduct(state) {
			return state.conversionProduct;
		},
		isExtensionOrAddExtensionOrderWithConversionProduct(state) {
			return state.isExtensionOrAddExtensionOrderWithConversionProduct;
		},
	},
	mutations: {
		INITIALIZE_APPLY(state, value) {
			const keys = Object.keys(initState);
			keys.map((key) => {
				state[key] = initState[key];
			});
		},
		SET_CURRENT_CHARGE_TYPE(state, value) {
			state.currentChargeType = value;
		},
		INIT_AGREE_TERM(state) {
			state.agreeTerm = false;
		},
		SET_AGREE_TERM(state, value) {
			state.agreeTerm = value;
		},
		INIT_ACCOUNT_AGREE_TERM(state) {
			state.accountAgreeTerm = false;
		},
		SET_ACCOUNT_AGREE_TERM(state, value) {
			state.accountAgreeTerm = value;
		},
		SET_TOTAL_QUOTA(state, value) {
			state.totQuota = value;
		},
		SET_BUSINESS_REGISTRATION(state, value) {
			state.businessRegistration = [];
			if (value.length > 0) {
				value.forEach((obj) => state.businessRegistration.push(obj));
			}
		},
		SET_START_DATE(state, value) {
			state.startDate = value;
		},
		SET_END_DATE(state, value) {
			state.endDate = value;
		},
		SET_USE_PERIOD(state, value) {
			state.usePeriod = value;
		},
		CLEAR_ALL_APPLY_INFO(state) {
			state.currentChargeType = '';
			state.startDate = '';
			state.endDate = '';
			state.totQuota = 0;
			state.usePeriod = 0;
			state.basicItemSelected = [];
			state.addonChannelSelectedList = [];
			state.orderTempCompanyInfo = {};
			state.companyManagerInfo = {};
			state.companySiteInfo = {};
			state.companyAdditionalInfo = {};
			state.termsAgreementInfo = null;
			state.estimateAllCompanyInfo = {
				companyInfo: {},
				companyManagerInfo: {},
			};
			state.estimateTermsAgreementInfo = null;
			state.payMethodInfo = {};
			state.agreeTerm = false;
			state.accountAgreeTerm = false;
		},
		CLEAR_APPLY_SERVICE_INFO(state) {
			state.basicItemSelected = [];
			state.addonChannelSelectedList = [];
		},
		CELAR_SELECT_CARD_OPTION(state) {
			state.selectedCardOption = '';
		},
		SET_CARD_OPTION(state, code) {
			state.selectedCardOption = code;
		},
		TOGGLE_FOLD(state, data) {
			let temp = null;
			if (data.type == 'base') {
				temp = [...state.basicItemSelected];
				if (data.typeDtl == 'defaultFee') {
					temp[0].defaultFeeDetailFold = !temp[0].defaultFeeDetailFold;
				} else if (data.typeDtl == 'membershipFee') {
					temp[0].membershipFeeDetailFold = !temp[0].membershipFeeDetailFold;
				} else if (data.typeDtl == 'addUserFee') {
					temp[0].addUserFeeDetailFold = !temp[0].addUserFeeDetailFold;
				}
				state.basicItemSelected = temp;
			} else if (data.type == 'addon') {
				temp = [...state.addonChannelSelectedList];
				let addonIndex = state.addonChannelSelectedList.findIndex((obj) => obj.id == data.itemId);
				if (data.typeDtl == 'defaultFee') {
					temp[addonIndex].defaultFeeDetailFold = !temp[addonIndex].defaultFeeDetailFold;
				} else if (data.typeDtl == 'membershipFee') {
					temp[addonIndex].membershipFeeDetailFold = !temp[addonIndex].membershipFeeDetailFold;
				} else if (data.typeDtl == 'addUserFee') {
					temp[addonIndex].addUserFeeDetailFold = !temp[addonIndex].addUserFeeDetailFold;
				}
				state.addonChannelSelectedList = temp;
			}
		},
		SET_BASIC_ITEM_SELECTED(state, value) {
			if (!value) return;
			// 기본서비스 선택
			state.basicItemSelected = [];
			value['optionItemSelected'] = [];
			value['defaultFeeDetailFold'] = true;
			value['membershipFeeDetailFold'] = true;
			value['addUserFeeDetailFold'] = true;
			value['showModal'] = false;
			value['usingDate'] = {
				startDate: state.startDate,
				endDate: state.endDate,
			};
			state.basicItemSelected.push(value);
		},
		ADD_ADDON_CHANNEL_SELECTED(state, value) {
			// 부가서비스 추가
			value['optionItemSelected'] = [];
			value['defaultFeeDetailFold'] = true;
			value['membershipFeeDetailFold'] = true;
			value['addUserFeeDetailFold'] = true;
			value['showModal'] = false;
			// 사용기간;
			value['usingDate'] = {
				startDate: state.startDate,
				endDate: state.endDate,
			};
			// 무료체험일 경우
			if (!value.type) {
				if (value.freeTrialPolicyModel) {
					if (value.freeTrialPolicyModel.usePolicy) {
						value.usingDate['endDateReal'] = value.usingDate.endDate;
						value.usingDate.endDate = value.freeTrialExpiredDate;
					}
				}
			}
			state.addonChannelSelectedList.push(value);
		},
		REMOVE_ADDON_CHANNEL_SELECTED(state, code) {
			// 부가서비스 삭제
			let index = state.addonChannelSelectedList.findIndex((obj) => obj.code == code);
			if (index > -1) {
				state.addonChannelSelectedList.splice(index, 1);
			}
		},
		UPDATE_ADDON_CHANNEL_SELECTED(state, value) {
			// 부가서비스 수정
			let index = state.addonChannelSelectedList.findIndex((obj) => obj.code == value.code);
			if (index > -1) {
				state.addonChannelSelectedList.splice(index, 1, value);
			} else {
				value['optionItemSelected'] = [];
				value['defaultFeeDetailFold'] = true;
				value['membershipFeeDetailFold'] = true;
				value['addUserFeeDetailFold'] = true;
				value['showModal'] = false;
				// 사용기간
				let useDay = parseInt(value.useperiod) * 30;
				let date = new Date();
				let year = date.getFullYear();
				let month = new String(date.getMonth() + 1);
				let day = new String(date.getDate());
				if (month.length == 1) {
					month = '0' + month;
				}
				if (day.length == 1) {
					day = '0' + day;
				}
				let startDate = year + '-' + month + '-' + day;

				date.setDate(date.getDate() + useDay);
				year = date.getFullYear();
				month = new String(date.getMonth() + 1);
				day = new String(date.getDate());
				if (month.length == 1) {
					month = '0' + month;
				}
				if (day.length == 1) {
					day = '0' + day;
				}
				let endDate = year + '-' + month + '-' + day;

				value['useDate'] = { startDate: startDate, endDate: endDate };
				state.addonChannelSelectedList.push(value);
			}
		},
		ADD_BASE_OPTION_SELECTED(state, value) {
			// 기본서비스 옵션상품 (+) 선택
			let index = state.basicItemSelected[0].optionItemSelected.findIndex((obj) => obj.id == value.id);
			let temp = [...state.basicItemSelected];

			if (index > -1) {
				temp[0].optionItemSelected.splice(index, 1, value);
			} else {
				temp[0].optionItemSelected.push(value);
			}
			state.basicItemSelected = temp;
		},
		SUBTRACT_BASE_OPTION_SELECTED(state, value) {
			// 기본서비스 옵션상품 (-) 선택
			let index = state.basicItemSelected[0].optionItemSelected.findIndex((obj) => obj.id == value.id);
			let temp = [...state.basicItemSelected];
			if (value.orderCount == 0) {
				temp[0].optionItemSelected.splice(index, 1);
			} else {
				temp[0].optionItemSelected.splice(index, 1, value);
			}
			state.basicItemSelected = temp;
		},
		ADD_ADDON_OPTION_SELECTED(state, value) {
			// 부가서비스 옵션상품 (+) 선택
			// 테스트 데이터 id가 없어서 ~
			if (value.data.code == null) {
				value.data.code = value.data.id;
			}

			let addonIndex = state.addonChannelSelectedList.findIndex(
				// 옵션상품이 속해있는 부가서비스 찾기
				(obj) => obj.code == value.addonCode,
			);
			let index = state.addonChannelSelectedList[addonIndex].optionItemSelected.findIndex(
				(obj) => obj.code == value.data.code,
			);
			let temp = [...state.addonChannelSelectedList];
			if (index > -1) {
				temp[addonIndex].optionItemSelected.splice(index, 1, value.data);
			} else {
				temp[addonIndex].optionItemSelected.push(value.data);
			}
			state.addonChannelSelectedList = temp;
		},
		SUBTRACT_ADDON_OPTION_SELECTED(state, value) {
			// 부가서비스 옵션상품 (-) 선택
			let addonIndex = state.addonChannelSelectedList.findIndex(
				// 옵션상품이 속해있는 부가서비스 찾기
				(obj) => obj.code == value.addonCode,
			);
			let index = state.addonChannelSelectedList[addonIndex].optionItemSelected.findIndex(
				(obj) => obj.code == value.data.code,
			);
			let temp = [...state.addonChannelSelectedList];
			if (value.data.orderCount == 0) {
				temp[addonIndex].optionItemSelected.splice(index, 1);
			} else {
				temp[addonIndex].optionItemSelected.splice(index, 1, value.data);
			}
			state.addonChannelSelectedList = temp;
		},
		SET_TERMS_AGREEMENT_INFO(state, value) {
			state.termsAgreementInfo = value;
		},
		SET_ALL_COMPANY_INFO(state, value) {
			// state.companyInfo = value.companyInfo;
			state.companyManagerInfo = value.companyManagerInfo;
			state.companySiteInfo = value.companySiteInfo;
			state.companyAdditionalInfo = value.companyAdditionalInfo;
		},
		SET_ESTIMATE_ALL_COMPANY_INFO(state, value) {
			state.estimateAllCompanyInfo = value;
		},
		SET_ESTIMATE_TERMS_AGREEMENT_INFO(state, value) {
			state.estimateTermsAgreementInfo = value;
		},
		SET_PAY_METHOD_INFO(state, value) {
			state.payMethodInfo = value;
		},
		SET_PROMOTION(state, values) {
			values.forEach((value) => {
				if ('CUSTOMER_STORE' === value.type) {
					state.promotionAdvertisemnetStore = value.data;
				} else if ('CUSTOMER_APPLY' === value.type) {
					state.promotionAdvertisemnetApply = value.data;
				} else if ('PROMOTION_DISCOUNT' === value.type) {
					state.promotionDiscount = value.data;
				} else if ('CUSTOMER_TOP' === value.type) {
					state.topBanner = value.data.banners;
				} else if ('CUSTOMER_HOME' === value.type) {
					state.homeBanner = value.data;
				}
			});
		},
		SET_COMPANY_INFO(state, value) {
			state.companyInfo = value;
		},
		SET_ORDER_TEMP_COMPANY_INFO(state, value) {
			state.orderTempCompanyInfo = value;
		},
		SET_CUSTOMER_HISTORY(state, value) {
			state.customerHistoryInfo.historyList = value.historyList;
			state.customerHistoryInfo.total = value.totalCount;
		},
		SET_CUSTOMER_USER_INFO(state, value) {
			state.customerUserInfo.userList = value.customerUserList;
			state.customerUserInfo.total = value.totalCount;
			state.customerUserInfo.typeList = value.typeList;
		},
		SET_CUSTOMER_HISTORY_INFO(state, value) {
			state.customerUserHistoryInfo.historyList = value.historyList;
			state.customerUserHistoryInfo.total = value.totalCount;
		},
		SET_CUSTOMER_TERMS_LIST(state, value) {
			state.customerTermsList = value;
		},
		SET_CONVERSION_PRODUCT(state, value) {
			state.conversionProduct = value;
		},
		SET_EXTENSION_OR_ADDEXTENSION_ORDER_WITH_CONVERSION_PRODUCT(state, value) {
			state.isExtensionOrAddExtensionOrderWithConversionProduct = value;
		},
		CLEAR_EXTENSION_OR_ADDEXTENSION_ORDER_WITH_CONVERSION_PRODUCT(state) {
			state.isExtensionOrAddExtensionOrderWithConversionProduct = false;
		},
	},
	actions: {
		async fetchEndDate({ commit }, params) {
			// 만료일 조회 (params : startDate, period)
			const { data } = await getEndDate(params);
			return data;
		},
		async fetchBaseChannelList({ commit }, params) {
			// 기본서비스 상품 조회 (params : 기본서비스 코드)
			const { data } = await getBaseChannelList(params);
			return data;
		},
		async fetchAddonChannelList({ commit }, params) {
			// 기본서비스에 딸려있는 부가서비스 상품 조회 (params : 기본서비스 코드 )
			const { data } = await getAddonChannelList(params);
			return data;
		},
		async fetchBaseChannelOptionItemList({ commit }, params) {
			// 기본서비스에 딸려있는 옵션 상품 리스트 조회 (params : 기본서비스 코드 )
			const { data } = await getBaseChannelOptionItemList(params);
			return data;
		},
		async fetchBaseChannelOptionItemByCode({ commit }, params) {
			// 기본서비스에 딸려있는 옵션 상품을 상품코드로 조회 (params : 옵셩상품 코드 )
			const { data } = await getBaseChannelOptionByCode(params);
			return data;
		},
		async fetchAddonChannelOptionItemList({ commit }, params) {
			// 부가서비스에 딸려있는 옵션 상품 리스트 조회 (params : 기본서비스 코드 )
			const { data } = await getAddonChannelOptionItemList(params);
			return data;
		},
		async fetchTodayPromotionList({ commit }) {
			// 프로모션 조회
			const { data } = await getTodayPromotionList();
			return data;
		},
		async fetchCustomerInfo({ commit }) {
			const response = await getCustomerInfo();
			commit('SET_COMPANY_INFO', response.data.companyInfo);
			commit('SET_BUSINESS_REGISTRATION', response.data.companyInfo?.businessLicenses);
			return response;
		},
		async fetchCustomerHistoryInfo({ commit }, params) {
			const response = await getCustomerHistoryInfo(params);
			commit('SET_CUSTOMER_HISTORY', response.data);
			return response;
		},
		async saveCustomer({ commit }, params) {
			const response = await setCustomerInfo(params);
			commit('SET_COMPANY_INFO', response.data.companyInfo);
			return response;
		},
		async GET_CUSTOMER_USER_INFO({ commit }, params) {
			const response = await getCustomerUserInfo(params);
			commit('SET_CUSTOMER_USER_INFO', response.data);
			return response;
		},
		async UPDATE_CUSTOMER_USER_ROLE({ commit }, params) {
			const response = await updateCustomerUserRole(params);
			return response;
		},
		async GET_CUSTOMER_USER_HISTORY_INFO({ commit }, params) {
			const response = await getCustomerUserHistoryInfo(params);
			commit('SET_CUSTOMER_HISTORY_INFO', response.data);
			return response;
		},
		async APPLY_CUSTOMER_USER({ commit }, params) {
			const response = await applyCustomerUser(params);
			return response;
		},
		async linkCustomerWithUser({ commit }, data) {
			const response = await linkCustomerWithAccount(data);
			commit('SET_CURRENT_USER', response.data);
			return response;
		},
		async setCustomerTermsList({ commit }, data) {
			const response = await getTerms();
			commit('SET_CUSTOMER_TERMS_LIST', response.data);
			return response;
		},
		async checkBasechannelIsConvertable({ commit }, params) {
			// 기본서비스 -> 신요금제 전환 대상 기본서비스 ID 조회 (params: 기본서비스 ID)
			const { data } = await checkBaseChannelIsConvertable(params);
			return data.data;
		},
	},
};
