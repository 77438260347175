const initState = {
	remainDay: {},
	originMembershipChargeType: '', // PAY / FREE / FREE_MONTHLY
	newMembershipChargeType: '', // PAY / FREE / FREE_MONTHLY
	enableNextOrder: true, // 통계용 ChargeType - PAY / FREE / FREE_MONTHLY
	fixedOrderType: '', // 고정 주문 타입 (무료일반 -> 유료 : EXTENSION, 무료일반 -> 무료월과금 : EXTENSION, 무료월과금 -> 유료 : CHANGE)
	addExtensionUserCnt: 0, // 추가연장 사용자
	totalUserCountFocus: false,
	dates: {
		addStartDate: '', // 추가 시작일 (오늘날짜)
		addEndDate: '', // 추가 종료일 (기존 종료일)
		extensionStartDate: '', // 연장 시작일 (기존 만료일 다음날)
		extensionEndDate: '', // 연장 만료일
	},
	usingMembership: null, // 사용중인 멤버십 정보
	baseChannel: [], // [{baseInfo: ~, add: {}, extension: {}, option : { code: ~, add: [], extension: [] } }]
	addonChannel: [], // [{baseInfo: ~, add: {}, extension: {}, option : { code: ~, add: [], extension: [] } }, {..}, ...]
	AddExtensionPrices: {
		totOriginPrice: 0,
		totDiscountPrice: 0,
		totPayPrice: 0,
		surtaxPrice: 0,
		finalPayPrice: 0,
	},
	totalExtensionStorage: 0,
	companyUsageStorage: {},
	companyUsageOptions: {},
	// 아래는 추가 연장 시 입력
	newSiteInfo: null, // 사업자등록번호, 부가서비스 정보
	payMethodInfo: null,
	refundableAmount: 0,
	govermentSupportBaseChannelCode: false,
	isInOrderProcess: false,
	totalOrderQuota: 0,
};
export default {
	state: { ...initState },
	getters: {
		getRefundableAmount(state) {
			return state.refundableAmount;
		},
		getRemainDay(state) {
			return state.remainDay;
		},
		getOriginMembershipChargeType(state) {
			return state.originMembershipChargeType;
		},
		getNewMembershipChargeType(state) {
			return state.newMembershipChargeType;
		},
		getEnableNextOrder(state) {
			return state.enableNextOrder;
		},
		getFixedOrderType(state) {
			return state.fixedOrderType;
		},
		getAddExtensionUserCnt(state) {
			return state.addExtensionUserCnt;
		},
		getTotalUserCountFocus(state) {
			return state.totalUserCountFocus;
		},
		getDates(state) {
			return state.dates;
		},
		getUsingMembership(state) {
			//선택된 기본서비스아이템 return
			return state.usingMembership;
		},
		getBaseChannel(state) {
			// 기본서비스(+옵션) return
			return state.baseChannel;
		},
		getAddonChannel(state) {
			// 부가서비스(+옵션) return
			return state.addonChannel;
		},
		getNewSiteInfo(state) {
			// 사업자등록번호, 부가서비스 정보 return
			return state.newSiteInfo;
		},
		getGovermentSupportBaseChannelCode(state) {
			return state.govermentSupportBaseChannelCode;
		},
		getAddExtensionPrices(state) {
			let totOriginPrice = 0; // 총 원가
			let totDiscountPrice = 0; //총 할인금액
			state.baseChannel.forEach((obj) => {
				if (obj.extension.length > 0) {
					if (obj.extension[0].membershipFeeNormalPrice) {
						totOriginPrice += obj.extension[0].membershipFeeNormalPrice; // 설치비
						totDiscountPrice += obj.extension[0].membershipFeeDiscount; // 기본료할인액
					}
					if (obj.extension[0].defaultFeeTotNormalPrice) {
						totOriginPrice += obj.extension[0].defaultFeeTotNormalPrice; // 기본료
						totDiscountPrice += obj.extension[0].defaultFeeTotDiscount; // 기본료할인액
					}
					if (obj.extension[0].addUserTotNormalPrice) {
						totOriginPrice += obj.extension[0].addUserTotNormalPrice; // 사용자추가금
						totDiscountPrice += obj.extension[0].addUserTotDiscount; // 사용자추가할인액
					}
				}
				if (obj.add.length > 0) {
					if (obj.add[0].membershipFeeNormalPrice) {
						totOriginPrice += obj.add[0].membershipFeeNormalPrice; // 설치비
						totDiscountPrice += obj.add[0].membershipFeeDiscount; // 기본료할인액
					}
					if (obj.add[0].defaultFeeTotNormalPrice) {
						totOriginPrice += obj.add[0].defaultFeeTotNormalPrice; // 기본료
						totDiscountPrice += obj.add[0].defaultFeeTotDiscount; // 기본료할인액
					}
					if (obj.add[0].addUserTotNormalPrice) {
						totOriginPrice += obj.add[0].addUserTotNormalPrice; // 사용자추가금
						totDiscountPrice += obj.add[0].addUserTotDiscount; // 사용자추가할인액
					}
				}

				obj.option.forEach((option) => {
					if (option.extension.length) {
						totOriginPrice += option.extension[0].finalPrice; // 설치비
					}
					if (option.add.length) {
						totOriginPrice += option.add[0].finalPrice; // 설치비
					}
				});
			});

			state.addonChannel.forEach((obj) => {
				if (obj.extension.length) {
					if (obj.extension[0].membershipFeeNormalPrice) {
						totOriginPrice += obj.extension[0].membershipFeeNormalPrice; // 설치비
						totDiscountPrice += obj.extension[0].membershipFeeDiscount; // 기본료할인액
					}
					if (obj.extension[0].defaultFeeTotNormalPrice) {
						totOriginPrice += obj.extension[0].defaultFeeTotNormalPrice; // 기본료
						totDiscountPrice += obj.extension[0].defaultFeeTotDiscount; // 기본료할인액
					}
					if (obj.extension[0].addUserTotNormalPrice) {
						totOriginPrice += obj.extension[0].addUserTotNormalPrice; // 사용자추가금
						totDiscountPrice += obj.extension[0].addUserTotDiscount; // 사용자추가할인액
					}
				}
				if (obj.add.length) {
					if (obj.add[0].membershipFeeNormalPrice) {
						totOriginPrice += obj.add[0].membershipFeeNormalPrice; // 설치비
						totDiscountPrice += obj.add[0].membershipFeeDiscount; // 기본료할인액
					}

					if (obj.add[0].defaultFeeTotNormalPrice) {
						totOriginPrice += obj.add[0].defaultFeeTotNormalPrice; // 기본료
						totDiscountPrice += obj.add[0].defaultFeeTotDiscount; // 기본료할인액
					}
					if (obj.add[0].addUserTotNormalPrice) {
						totOriginPrice += obj.add[0].addUserTotNormalPrice; // 사용자추가금
						totDiscountPrice += obj.add[0].addUserTotDiscount; // 사용자추가할인액
					}
				}
				obj.option.forEach((option) => {
					if (option.extension.length) {
						totOriginPrice += option.extension[0].finalPrice; // 설치비
					}
					if (option.add.length) {
						totOriginPrice += option.add[0].finalPrice; // 설치비
					}
				});
			});

			const totPayPrice =
				parseInt(totOriginPrice) +
				parseInt(totDiscountPrice) -
				(state.fixedOrderType === 'CHANGE' ? state.refundableAmount : 0);
			const surtaxPrice = totPayPrice > 0 ? totPayPrice * 0.1 : 0; // 부가세 10%
			const finalPayPrice = totPayPrice > 0 ? totPayPrice + surtaxPrice : 0;
			// 최종금액 원단위 절사

			return {
				totOriginPrice: totOriginPrice,
				totDiscountPrice: totDiscountPrice,
				totPayPrice: totPayPrice, // VAT 미포함
				surtaxPrice: surtaxPrice, // VAT
				finalPayPrice: finalPayPrice, // VAT 포함 최종금액
			};
		},
		getTotalExtensionStorage(state) {
			return state.totalExtensionStorage;
		},
		getCompanyUsageStorage(state) {
			return state.companyUsageStorage;
		},
		getIsInOrderProcess(state) {
			return state.isInOrderProcess;
		},
		getTotalOrderQuota(state) {
			return state.totalOrderQuota;
		},
	},
	mutations: {
		INITIALIZE_ADD_EXTENSION(state, value) {
			const keys = Object.keys(initState);
			keys.map((key) => {
				state[key] = initState[key];
			});
		},
		SET_REFUNDABLE_AMOUNT(state, value) {
			state.refundableAmount = value;
		},
		CLEAR_ADD_DATA(state) {
			if (state.baseChannel.length) {
				state.baseChannel[0].add = [];
				state.baseChannel[0].option.forEach((option) => (option.add = []));
			}
			if (state.addonChannel.length) {
				state.addonChannel.forEach((service) => {
					service.add = [];
					service.option.forEach((option) => (option.add = []));
				});
			}
		},
		SET_REMAIN_DAY(state, value) {
			state.remainDay = value;
		},
		SET_ORIGIN_MEMBERSHIP_CHARGE_TYPE(state, value) {
			state.originMembershipChargeType = value;
		},
		SET_NEW_MEMBERSHIP_CHARGE_TYPE(state, value) {
			state.newMembershipChargeType = value;
		},
		SET_ENABLE_NEXT_ORDER(state, value) {
			state.enableNextOrder = value;
		},
		SET_FIXED_ORDER_TYPE(state, value) {
			state.fixedOrderType = value;
		},
		SET_ADD_EXTENSION_USER_COUNT(state, value) {
			state.addExtensionUserCnt = value;
		},
		SET_TOTAL_USER_COUNT_FOCUS(state, value) {
			state.totalUserCountFocus = value;
		},
		SET_GOVERNMENT_SUPPORT_BASE_CHANNEL_CODE(state, value) {
			state.govermentSupportBaseChannelCode = value;
		},
		SET_DATES(state, value) {
			if (value.addStartDate) {
				state.dates.addStartDate = value.addStartDate;
			}
			if (value.addEndDate) {
				state.dates.addEndDate = value.addEndDate;
			}
			if (value.extensionStartDate) {
				state.dates.extensionStartDate = value.extensionStartDate;
			}
			if (value.extensionEndDate) {
				state.dates.extensionEndDate = value.extensionEndDate;
			}
		},
		CLEAR_ALL_ADD_EXTENSION_INFO(state) {
			state.usingMembership = null;
			state.baseChannel = [];
			state.addonChannel = [];
			state.payMethodInfo = {};
		},
		SET_NEW_SITE_INFO(state, value) {
			state.newSiteInfo = value;
		},
		// 사용중 멤버십 setting
		SET_USING_MEMBERSHIP(state, value) {
			state.usingMembership = null;
			state.usingMembership = value;
		},
		// 추가/연장 기본/부가서비스 insert/update
		SET_SERVICE(state, payload) {
			/*payload
			- serviceType : BASE/ ADDON
			- operationType : EXTENSION/ ADD
			- service : serviceModel
			*/
			const useAdd =
				state.originMembershipChargeType === state.newMembershipChargeType ||
				(state.originMembershipChargeType === 'FREE_MONTHLY' && state.newMembershipChargeType === 'PAY') ||
				(state.originMembershipChargeType === 'FREE_MONTHLY' && state.newMembershipChargeType === 'FREE');
			if (!useAdd && payload.operationType === 'ADD') {
				return;
			}

			payload.service['defaultFeeDetailFold'] = true;
			payload.service['membershipFeeDetailFold'] = true;
			payload.service['addUserFeeDetailFold'] = true;
			let serviceList = null;
			if (payload.serviceType === 'BASE') {
				serviceList = state.baseChannel; //참조
			} else if (payload.serviceType === 'ADDON') {
				serviceList = state.addonChannel; //참조
			}
			if (serviceList.length == 0) {
				// 신규추가
				const emptyObejct = {
					baseInfo: {
						name: payload.service.name,
						code: payload.service.code,
						serviceCategoryModel: payload.service.serviceCategoryModel,
						defaultFeePolicyModel: payload.service.defaultFeePolicyModel,
						addUserIntervalPolicyModel: payload.service.addUserIntervalPolicyModel,
						membershipFeePolicyModel: payload.service.membershipFeePolicyModel,
						freeTrialPolicyModel: payload.service.freeTrialPolicyModel,
						chargeType: payload.service.chargeType,
						termsSectionModel: payload.service.termsSectionModel,
						usingCount: payload.service.usingCount,
						usingDate: state.dates,
						integrationCode: payload.service.integrationInfoModel?.code,
					},
					add: [],
					extension: [],
					option: [], // {add:[], extension:[]}
				};
				if (payload.operationType == 'EXTENSION') {
					emptyObejct.extension.push(payload.service);
				} else if (payload.operationType == 'ADD') {
					emptyObejct.add.push(payload.service);
				}
				serviceList.push(emptyObejct);
			} else {
				// 추가 or 연장 선택되있는지
				let serviceIdx = serviceList.findIndex((obj) => obj.baseInfo.code == payload.service.code);

				if (serviceIdx === -1) {
					// 신규추가
					const emptyObejct = {
						baseInfo: {
							name: payload.service.name,
							code: payload.service.code,
							serviceCategoryModel: payload.service.serviceCategoryModel,
							defaultFeePolicyModel: payload.service.defaultFeePolicyModel,
							addUserIntervalPolicyModel: payload.service.addUserIntervalPolicyModel,
							membershipFeePolicyModel: payload.service.membershipFeePolicyModel,
							freeTrialPolicyModel: payload.service.freeTrialPolicyModel,
							chargeType: payload.service.chargeType,
							termsSectionModel: payload.service.termsSectionModel,
							usingCount: payload.service.usingCount,
							usingDate: state.dates,
						},
						add: [],
						extension: [],
						option: [], // {add:[], extension:[]}
					};
					if (payload.operationType == 'EXTENSION') {
						emptyObejct.extension.push(payload.service);
					} else if (payload.operationType == 'ADD') {
						emptyObejct.add.push(payload.service);
					}
					if (payload.serviceType == 'BASE') {
						serviceList.splice(0, 1);
					}
					serviceList.push(emptyObejct);
				} else {
					let tempService = { ...serviceList[serviceIdx] }; //복사
					if (payload.operationType == 'EXTENSION') {
						tempService.extension = [];
						tempService.extension.push(payload.service);
					} else if (payload.operationType == 'ADD') {
						tempService.add = [];
						tempService.add.push(payload.service);
					}
					serviceList.splice(serviceIdx, 1, tempService);
				}
			}
		},
		// 추가/연장 기본/부가서비스 삭제
		REMOVE_SERVICE(state, payload) {
			/*payload
			- serviceType : BASE/ ADDON
			- operationType : EXTENSION/ ADD
			- service : serviceModel
			*/
			let serviceList = null;
			if (payload.serviceType == 'BASE') {
				serviceList = state.baseChannel; //참조
			} else if (payload.serviceType == 'ADDON') {
				serviceList = state.addonChannel; //참조
			}

			if (serviceList.length == 0) {
				return;
			}
			let serviceIdx = serviceList.findIndex((obj) => obj.baseInfo.code == payload.service.code);

			if (serviceIdx == -1) {
				return;
			} else {
				let tempService = { ...serviceList[serviceIdx] }; //복사
				if (
					(payload.operationType == 'EXTENSION' && tempService.add.length == 0) ||
					(payload.operationType == 'ADD' && tempService.extension.length == 0)
				) {
					// 추가도 없으면 기본 서비스 데이터 빼기
					serviceList.splice(serviceIdx, 1);
				} else {
					if (payload.operationType == 'EXTENSION') {
						tempService.extension = [];
					} else if (payload.operationType == 'ADD') {
						tempService.add = [];
					}
					serviceList.splice(serviceIdx, 1, tempService);
				}
			}
		},
		// 연장/추가 기본/부가서비스 옵션상품 (+) 선택
		PLUS_SERVICE_OPTION(state, payload) {
			/* payload :
			- serviceType : BASE / ADDON
			- service : 속해있는 서비스 정보(code, name, serviceCategoryModel)
			- operationType : ADD/ EXTENSION (추가/연장)
			- option : 서비스 옵션 정보
			*/
			const useAdd =
				state.originMembershipChargeType === state.newMembershipChargeType ||
				(state.originMembershipChargeType === 'FREE_MONTHLY' && state.newMembershipChargeType === 'PAY') ||
				(state.originMembershipChargeType === 'FREE_MONTHLY' && state.newMembershipChargeType === 'FREE');

			if (!useAdd && payload.operationType === 'ADD') {
				return;
			}

			let serviceList = null;
			if (payload.serviceType == 'BASE') {
				serviceList = state.baseChannel; // 참조
			} else if (payload.serviceType == 'ADDON') {
				serviceList = state.addonChannel; // 참조
			}

			if (serviceList.length == 0) {
				// 신규 추가
				const emptyObejct = {
					baseInfo: {
						name: payload.service.name,
						code: payload.service.code,
						serviceCategoryModel: payload.service.serviceCategoryModel,
						defaultFeePolicyModel: payload.service.defaultFeePolicyModel,
						addUserIntervalPolicyModel: payload.service.addUserIntervalPolicyModel,
						membershipFeePolicyModel: payload.service.membershipFeePolicyModel,
						freeTrialPolicyModel: payload.service.freeTrialPolicyModel,
						chargeType: payload.service.chargeType,
						termsSectionModel: payload.service.termsSectionModel,
						usingCount: payload.service.usingCount,
						usingDate: state.dates,
					},
					add: [],
					extension: [],
					option: [], // {code : ~, add:[], extension:[]}
				};
				const optionObject = {
					code: payload.option.code,
					itemType: payload.option.itemType,
					channelItemSalesSectionModel: payload.option.channelItemSalesSectionModel,
					termsSectionModel: payload.option.termsSectionModel,
					add: [],
					extension: [],
				};
				if (payload.operationType == 'EXTENSION') {
					optionObject.extension.push(payload.option);
				} else if (payload.operationType == 'ADD') {
					optionObject.add.push(payload.option);
				}

				emptyObejct.option.push(optionObject);
				serviceList.push(emptyObejct);
			} else {
				var serviceIdx = -1;
				serviceIdx = serviceList.findIndex((channel) => channel.baseInfo.code == payload.service.code);
				if (serviceIdx > -1) {
					let tempService = { ...serviceList[serviceIdx] }; // 서비스 데이터 복사
					if (tempService.option.length > 0) {
						let optionIndex = -1;
						optionIndex = tempService.option.findIndex(
							(option) => option.code == payload.option.code, // 옵션 데이터가 있는지 check
						);

						if (optionIndex > -1) {
							const optionObject = { ...tempService.option[optionIndex] }; // 옵션 데이터 복사
							if (payload.operationType == 'EXTENSION') {
								optionObject.extension = [];
								optionObject.extension.push(payload.option);
							} else if (payload.operationType == 'ADD') {
								optionObject.add = [];
								optionObject.add.push(payload.option);
							}
							tempService.option.splice(optionIndex, 1, optionObject);
						} else {
							const optionObject = {
								code: payload.option.code,
								itemType: payload.option.itemType,
								channelItemSalesSectionModel: payload.option.channelItemSalesSectionModel,
								termsSectionModel: payload.option.termsSectionModel,
								add: [],
								extension: [],
							};
							if (payload.operationType == 'EXTENSION') {
								optionObject.extension.push(payload.option);
							} else if (payload.operationType == 'ADD') {
								optionObject.add.push(payload.option);
							}
							tempService.option.push(optionObject);
						}
					} else {
						const optionObject = {
							code: payload.option.code,
							itemType: payload.option.itemType,
							channelItemSalesSectionModel: payload.option.channelItemSalesSectionModel,
							termsSectionModel: payload.option.termsSectionModel,
							add: [],
							extension: [],
						};
						if (payload.operationType == 'EXTENSION') {
							optionObject.extension.push(payload.option);
						} else if (payload.operationType == 'ADD') {
							optionObject.add.push(payload.option);
						}
						tempService.option.push(optionObject);
					}
					serviceList.splice(serviceIdx, 1, tempService);
				} else {
					// push
					const emptyObejct = {
						baseInfo: {
							name: payload.service.name,
							code: payload.service.code,
							serviceCategoryModel: payload.service.serviceCategoryModel,
							defaultFeePolicyModel: payload.service.defaultFeePolicyModel,
							addUserIntervalPolicyModel: payload.service.addUserIntervalPolicyModel,
							membershipFeePolicyModel: payload.service.membershipFeePolicyModel,
							freeTrialPolicyModel: payload.service.freeTrialPolicyModel,
							chargeType: payload.service.chargeType,
							termsSectionModel: payload.service.termsSectionModel,
							usingCount: payload.service.usingCount,
							usingDate: state.dates,
						},
						add: [],
						extension: [],
						option: [], // {code : ~, add:[], extension:[]}
					};
					const optionObject = {
						code: payload.option.code,
						itemType: payload.option.itemType,
						channelItemSalesSectionModel: payload.option.channelItemSalesSectionModel,
						termsSectionModel: payload.option.termsSectionModel,
						add: [],
						extension: [],
					};
					if (payload.operationType == 'EXTENSION') {
						optionObject.extension.push(payload.option);
					} else if (payload.operationType == 'ADD') {
						optionObject.add.push(payload.option);
					}
					emptyObejct.option.push(optionObject);
					serviceList.push(emptyObejct);
				}
			}
		},
		// 연장/추가 부가/기본서비스 옵션상품 (-) 선택
		MINUS_SERVICE_OPTION(state, payload) {
			/* payload :
			- serviceType : BASE / ADDON
			- service : 속해있는 서비스 정보(code, name, serviceCategoryModel)
			- operationType : ADD/ EXTENSION (추가/연장)
			- option : 서비스 옵션 정보
			*/

			let serviceList = null;
			if (payload.serviceType == 'BASE') {
				serviceList = state.baseChannel; // 참조
			} else if (payload.serviceType == 'ADDON') {
				serviceList = state.addonChannel; // 참조
			}

			if (serviceList.length == 0) {
				return;
			}
			// 해당 서비스 index 구하기
			var serviceIdx = -1;
			serviceIdx = serviceList.findIndex((service) => service.baseInfo.code == payload.service.code);

			if (serviceIdx == -1) {
				return;
			}

			let tempService = { ...serviceList[serviceIdx] }; // 서비스 복사

			if (tempService.option.length == 0) {
				return;
			}

			let optionIndex = -1;
			optionIndex = tempService.option.findIndex((option) => option.code == payload.option.code);

			if (optionIndex == -1) {
				return;
			}

			const optionObject = { ...tempService.option[optionIndex] };
			// option extension 데이터 삭제 또는 변경
			if (payload.option.orderCount < 1) {
				// orderCount 0이면 삭제
				if (
					(payload.operationType == 'EXTENSION' && optionObject.add.length == 0) ||
					(payload.operationType == 'ADD' && optionObject.extension.length == 0)
				) {
					if (tempService.add.length == 0 && tempService.extension.length == 0 && tempService.option.length == 1) {
						// 추가/연장 서비스, 다른 옵션 없으면 서비스 삭제
						serviceList.splice(serviceIdx, 1);
						return;
					} else {
						// 연장, 추가 옵션 다 없으면 옵션 삭제
						tempService.option.splice(optionIndex, 1);
					}
				} else {
					if (payload.operationType == 'EXTENSION') {
						optionObject.extension = [];
					} else if (payload.operationType == 'ADD') {
						optionObject.add = [];
					}
					tempService.option.splice(optionIndex, 1, optionObject);
				}
			} else {
				if (payload.operationType == 'EXTENSION') {
					optionObject.extension = [];
					optionObject.extension.push(payload.option);
				} else if (payload.operationType == 'ADD') {
					optionObject.add = [];
					optionObject.add.push(payload.option);
				}
				tempService.option.splice(optionIndex, 1, optionObject);
			}
			serviceList.splice(serviceIdx, 1, tempService);
		},
		// 연장/추가 부가/기본서비스 옵션상품 제외
		REMOVE_SERVICE_OPTION(state, payload) {
			/* payload :
			- serviceType : BASE / ADDON
			- service : 속해있는 서비스 정보(code, name, serviceCategoryModel)
			- operationType : ADD/ EXTENSION (추가/연장)
			- option : 서비스 옵션 정보
			*/
			let serviceList = null;
			if (payload.serviceType == 'BASE') {
				serviceList = state.baseChannel; // 참조
			} else if (payload.serviceType == 'ADDON') {
				serviceList = state.addonChannel; // 참조
			}
			if (serviceList.length == 0) {
				return;
			}
			// 해당 서비스 index 구하기
			var serviceIdx = -1;
			serviceIdx = serviceList.findIndex((service) => service.baseInfo.code == payload.service.code);

			if (serviceIdx == -1) {
				return;
			}

			let tempService = { ...serviceList[serviceIdx] }; // 서비스 복사

			if (tempService.option.length == 0) {
				return;
			}

			let optionIndex = tempService.option.findIndex((option) => option.code == payload.option.code);

			if (optionIndex == -1) {
				return;
			}
			const optionObject = { ...tempService.option[optionIndex] };
			if (
				(payload.operationType == 'EXTENSION' && optionObject.add.length == 0) ||
				(payload.operationType == 'ADD' && optionObject.extension.length == 0)
			) {
				if (tempService.add.length == 0 && tempService.extension.length == 0 && tempService.option.length == 1) {
					// 추가/연장 서비스, 다른 옵션 없으면 서비스 삭제
					serviceList.splice(serviceIdx, 1);
					return;
				} else {
					// 연장, 추가 옵션 다 없으면 옵션 삭제
					tempService.option.splice(optionIndex, 1);
				}
			} else {
				if (payload.operationType == 'EXTENSION') {
					optionObject.extension = [];
				} else if (payload.operationType == 'ADD') {
					optionObject.add = [];
				}
				tempService.option.splice(optionIndex, 1, optionObject);
			}
			serviceList.splice(serviceIdx, 1, tempService);
		},
		TOGGLE_ADD_EXTENSION_FOLD(state, data) {
			/* data :
				type: type, // base, addon
				opertationType: opertationType, // ADD, EXTENSION
				typeDtl: typeDtl, // defaultFee, membershipFee, addUserFee
				code: code, // (부가일때)아이디
			*/
			let serviceList = null;
			let service = null;
			if (data.type == 'base') {
				serviceList = [...state.baseChannel];
				if (data.opertationType == 'ADD') {
					service = { ...serviceList[0].add[0] };
				} else if (data.opertationType == 'EXTENSION') {
					service = { ...serviceList[0].extension[0] };
				}
				if (data.typeDtl == 'defaultFee') {
					service.defaultFeeDetailFold = !service.defaultFeeDetailFold;
				} else if (data.typeDtl == 'membershipFee') {
					service.membershipFeeDetailFold = !service.membershipFeeDetailFold;
				} else if (data.typeDtl == 'addUserFee') {
					service.addUserFeeDetailFold = !service.addUserFeeDetailFold;
				}

				if (data.opertationType == 'ADD') {
					serviceList[0].add.splice(0, 1, service);
				} else if (data.opertationType == 'EXTENSION') {
					serviceList[0].extension.splice(0, 1, service);
				}
				state.baseChannel = serviceList;
			} else if (data.type == 'addon') {
				serviceList = [...state.addonChannel];
				let addonIndex = serviceList.findIndex((obj) => obj.baseInfo.code == data.code);
				if (data.opertationType == 'ADD') {
					service = { ...serviceList[addonIndex].add[0] };
				} else if (data.opertationType == 'EXTENSION') {
					service = { ...serviceList[addonIndex].extension[0] };
				}

				if (data.typeDtl == 'defaultFee') {
					service.defaultFeeDetailFold = !service.defaultFeeDetailFold;
				} else if (data.typeDtl == 'membershipFee') {
					service.membershipFeeDetailFold = !service.membershipFeeDetailFold;
				} else if (data.typeDtl == 'addUserFee') {
					service.addUserFeeDetailFold = !service.addUserFeeDetailFold;
				}
				if (data.opertationType == 'ADD') {
					serviceList[addonIndex].add.splice(0, 1, service);
				} else if (data.opertationType == 'EXTENSION') {
					serviceList[addonIndex].extension.splice(0, 1, service);
				}
				state.addonChannel = serviceList;
			}
		},
		SET_COMPANY_USAGE_STORAGE(state, payload) {
			state.companyUsageStorage = payload;
		},
		CALCULATE_TOTAL_EXTENSION_STORAGE(state) {
			let sumExtensionStorage = 0;

			if (state.baseChannel[0] !== undefined) {
				state.baseChannel[0].option.forEach((item) => {
					if (item.itemType === 'VOLUME' && item.extension[0] != undefined) {
						sumExtensionStorage += Number(item.extension[0].itemValue) * Number(item.extension[0].orderCount);
					}
				});
				state.totalExtensionStorage = sumExtensionStorage;
			}
		},
		SET_IS_IN_ORDER_PROCESS(state, value) {
			state.isInOrderProcess = value;
		},
		CALCULATE_TOTAL_ORDER_QUOTA(state, payload) {
			state.totalOrderQuota = parseInt(payload.totalOrderStorage) + parseInt(payload.totalAddUserVolume);
		},
	},
};
