import {
	cancelAutoPaymentCardInfo,
	cancleOrder,
	changeAddonAdmin,
	changePayType,
	checkdomain,
	checkMultiCompany,
	checkurl,
	createSite,
	deleteSite,
	fetchSiteStatus,
	getAccountUsage,
	getAdditionalOptions,
	getCompanyUsage,
	getMembership,
	getMemberships,
	getOrder,
	getOrderList,
	getPartner,
	getRemainDays,
	putAccountOnOff,
	linkDaouOffice,
	ssoDaouOffice,
	linkOpenlounge,
	saveAutoPaymentCardInfo,
	searchAutoPaymentCardInfo,
	setAutoExtension,
	setSiteStatus,
	updateCustomer,
	updateSite,
	userSso,
	fetchCurrentUser,
	deleteProfile,
} from '@/api/user';
import _ from 'lodash';
import { getOrderHistories } from '@/api/order';
import { checkBaseChannelIsConvertable } from '@/api/product';

const initState = {
	sso: {
		role: 'SITE',
	},
	membership: {},
	partnerInfo: null,
	siteStatus: {
		sites: [],
	},
};
export default {
	state: { ...initState },
	getters: {
		getPartnerInfo(state) {
			return state.partnerInfo;
		},
		getServiceType(state) {
			let membership = state.membership;
			let serviceType;
			if (!_.isUndefined(membership.baseChannelInfoModel)) {
				serviceType = membership.baseChannelInfoModel.baseChannelModel.serviceCategoryModel.serviceType;
			}
			return serviceType;
		},
		isSaaS(state, getters) {
			return getters.getServiceType === 'SAAS';
		},
		isInstall(state, getters) {
			return getters.getServiceType !== 'SAAS';
		},
		isSaaSReseller(state, getters) {
			return getters.isSaaS && state.membership.partnerSimpleModel.salesType === 'RESELLER';
		},
		isSaaSNotReseller(state, getters) {
			return getters.isSaaS && state.membership.partnerSimpleModel.salesType !== 'RESELLER';
		},
	},
	mutations: {
		INITIALIZE_USER(state, value) {
			const keys = Object.keys(initState);
			keys.map((key) => {
				state[key] = initState[key];
			});
		},
		SET_PARTNER_INFO(state, value) {
			state.partnerInfo = value;
		},
		GOT_MEMBERSHIP(state, data) {
			state.membership = data;
		},
		MEMBERSHIPS(state, data) {
			state.memberships = data;
		},
		USER_SSO(state, data) {
			state.sso = data;
		},
		FETCH_SITE_STATUS(state, data) {
			data.sites.forEach((site) => {
				site.purchaseAccountQuota = site.purchaseAccountQuota || 0;
				site.purchaseCompanyQuota = site.purchaseCompanyQuota || 0;
				site.usedBankAccountCount = site.usedBankAccountCount || 0;
				site.usedPersonalCardCount = site.usedPersonalCardCount || 0;
			});
			state.siteStatus = Object.assign({}, data, {
				purchaseAccountQuota: data.purchaseAccountQuota || 0,
				purchaseCompanyQuota: data.purchaseCompanyQuota || 0,
				sites: data.sites,
			});
		},
		SET_SITE_STATUS(state, siteStatus) {
			state.siteStatus = siteStatus;
		},
		CREATE_SITE(state, site) {
			const { sites } = state.siteStatus;
			state.siteStatus.sites = [...sites, site];
		},
		UPDATE_SITE(state, site) {
			const { sites } = state.siteStatus;
			const siteIndex = sites.findIndex((item) => item.uuid === site.uuid);
			state.siteStatus.sites = [...sites.slice(0, siteIndex), site, ...sites.slice(siteIndex + 1)];
		},
		DELETE_SITE(state, uuid) {
			const { sites } = state.siteStatus;
			const deletedSiteIndex = sites.findIndex((site) => site.uuid === uuid);
			sites.splice(deletedSiteIndex, 1);
		},
		PUT_ACCOUNT_ONOFF(state, payload) {
			const { sites } = state.siteStatus;
			const siteIndex = sites.findIndex((item) => item.uuid === payload.uuid);
			const site = sites[siteIndex];
			const channelInfoIndex = site.channelInfo.findIndex((item) => item.code === 'ACCOUNT');
			const accountChannelInfo = site.channelInfo[channelInfoIndex] || {};
			const newAccountChannelInfo = { ...accountChannelInfo, use: payload.params.use, useMiniGnb: payload.params.use };
			const newChannelInfo = (site.channelInfo = [
				...site.channelInfo.slice(0, channelInfoIndex),
				newAccountChannelInfo,
				...site.channelInfo.slice(channelInfoIndex + 1),
			]);
			const updatedSite = { ...site, channelInfo: newChannelInfo };
			state.siteStatus.sites = [...sites.slice(0, siteIndex), updatedSite, ...sites.slice(siteIndex + 1)];
		},
	},
	actions: {
		async fetchAdditionalOptions({ commit }, params) {
			const { data } = await getAdditionalOptions(params);
			return data;
		},
		async fetchPartner({ commit }, params) {
			const { data } = await getPartner(params);
			return data;
		},
		async setAutoExtension({ commit }, params) {
			const { data } = await setAutoExtension(params);
			return data;
		},
		async checkAdmin({ commit }, params) {
			const { data } = await checkdomain(params);
			return data;
		},
		async checkUrl({ commit }, params) {
			const { data } = await checkurl(params);
			return data;
		},
		async checkMultiCompany({ commit }, params) {
			const { data } = await checkMultiCompany(params);
			return data;
		},
		async cancelOrder({ commit }, params) {
			const { data } = await cancleOrder(params);
			return data;
		},
		async fetchOrder({ commit }, params) {
			const { data } = await getOrder(params);
			return data;
		},
		async fetchOrderList({ commit }, params) {
			const { data } = await getOrderList(params);
			return data;
		},
		async fetchMembership({ commit }, id) {
			// 멤버십 조회
			const { data } = await getMembership(id);
			commit('GOT_MEMBERSHIP', data);
			return data;
		},
		async fetchRemainDays({ commit }, params) {
			// 멤버십 조회
			const { data } = await getRemainDays(params);
			return data;
		},

		async updateCustomer({ commit }, params) {
			// 고객정보 수정
			const { data } = await updateCustomer(params);
			return data;
		},
		// 자동결제 카드 정보
		async saveAutoPaymentCardInfo({ commit }, params) {
			// 등록
			const { data } = await saveAutoPaymentCardInfo(params);
			return data;
		},
		async cancelAutoPaymentCardInfo({ commit }, params) {
			// 삭제
			await cancelAutoPaymentCardInfo(params);
		},
		async searchAutoPaymentCardInfo({ commit }, params) {
			const { data } = await searchAutoPaymentCardInfo(params);
			return data;
		},
		async fetchCompanyUsage({ commit }, params) {
			const { data } = await getCompanyUsage(params);
			return data;
		},
		async fetchAccountUsage({ commit }, params) {
			const { data } = await getAccountUsage(params);
			return data;
		},
		async changePayType({ commit }, params) {
			// 저장
			const { data } = await changePayType(params);
			return data;
		},
		async userSso({ commit }, token) {
			const resp = await userSso(token);
			commit('USER_SSO', resp.data);
			return resp;
		},
		async fetchSiteStatus({ commit }, membershipId) {
			const resp = await fetchSiteStatus(membershipId);
			commit('FETCH_SITE_STATUS', resp);
			return resp;
		},
		async setSiteStatus({ commit }, data) {
			const resp = await setSiteStatus(data.siteStatus.sites, data.membershipId);
			if (resp.data.success) commit('SET_SITE_STATUS', data.siteStatus);
			return resp;
		},
		async createSite({ commit }, data) {
			data.siteStatus.sites = data.siteStatus.sites.slice(0, -1);
			const resp = await setSiteStatus(data.siteStatus.sites, data.membershipId, data.uuid);
			if (resp.data.success) {
				commit('SET_SITE_STATUS', data.siteStatus);
				const createSiteResp = await createSite(data);
				if (createSiteResp.data.success) {
					let membershipId = this.state.user.sso.membershipId;
					const siteStatusResp = await fetchSiteStatus(membershipId);
					commit('FETCH_SITE_STATUS', siteStatusResp);
				}
				return createSiteResp;
			}
			return resp;
		},
		async updateSite({ commit }, data) {
			const resp = await updateSite(data);
			if (resp.data.success) {
				commit('UPDATE_SITE', data.site);
			}
			return resp;
		},
		async deleteSite({ commit }, data) {
			const resp = await deleteSite(data);
			if (resp.data.success) {
				commit('DELETE_SITE', data.uuid);
			}
			return resp;
		},
		async putAccountOnOff({ commit }, payload) {
			const { data } = await putAccountOnOff(payload);
			if (data.status === 200) {
				commit('PUT_ACCOUNT_ONOFF', payload);
			}
			return data;
		},
		async changeAddonAdmin({ state }, payload) {
			const { data } = await changeAddonAdmin(payload);
			return data;
		},
		async linkOpenLounge({ commit }, payload) {
			const response = await linkOpenlounge(payload);
			return response.data;
		},
		async linkDaouOffice({ commit }, payload) {
			const response = await linkDaouOffice(payload);
			commit('SET_CURRENT_USER', response.data);
			return response.data;
		},
		async ssoDaouOffice({ commit }, payload) {
			const response = await ssoDaouOffice(payload);
			if (!_.isEmpty(response.headers.authorization)) {
				sessionStorage.setItem('authToken', response.headers.authorization);
			}
			return response;
		},
		async fetchOrderHistories({ commit }, payload) {
			const response = await getOrderHistories(payload);
			return response;
		},
		async fetchMemberships({ commit }, params) {
			// 멤버십 조회
			const { data } = await getMemberships();
			const res = data.filter((item) => item.status !== 'END');
			commit('MEMBERSHIPS', res);
			return res;
		},
		async fetchCurrentUser({ commit }) {
			const { data } = await fetchCurrentUser();
			commit('SET_CURRENT_USER', data);
			return data;
		},
		async deleteProfile({ commit }) {
			const { data } = await deleteProfile();
			commit('DELETE_CURRENT_USER_PROFILE');
			return data;
		},
		async checkBaseChannelIsConvertable({ commit }, params) {
			// 기본서비스 -> 신요금제 전환 대상 기본서비스 ID 조회 (params: 기본서비스 ID)
			const { data } = await checkBaseChannelIsConvertable(params);
			return data.data;
		},
	},
};
