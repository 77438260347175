import Vue from 'vue';

import VueRouter from 'vue-router';

import store from '../store/index';
import { HOME } from '@/constants';

Vue.use(VueRouter);

const saveCurrentStep = (type) => (to, from, next) => {
	const payload = {
		type: type,
		step: to.name.toLowerCase(),
	};
	store.commit('SET_CURRENT_STEP', payload);
	next();
};

function gtag() {
	window.dataLayer.push(arguments);
}

//구글 애널리틱스 페이지 초기화
const initializeGoogleAnalytics = () => {
	let gtagId = process.env.VUE_APP_GOOGLE_TAG_ID;
	if (gtagId) {
		gtag('config', gtagId);
	}
};

const routes = [
	{
		path: '',
		redirect: { name: HOME },
	},
	{
		path: '/',
		component: () => import('@/views/Layout.vue'),
		meta: { auth: true },
		children: [
			{
				path: 'home',
				name: HOME,
				component: () => import('@/views/Home.vue'),
			},
			{
				path: '/profile',
				name: 'Profile',
				component: () => import('@/views/profile/Profile.vue'),
				props: true,
			},
			{
				path: '/secession',
				name: 'Secession',
				component: () => import('@/views/profile/Secession.vue'),
				props: true,
			},
			{
				path: '/users',
				component: () => import('@/views/company/UserManagement.vue'),
			},
			{
				path: '/dev',
				component: () => import('@/views/login/Dev.vue'),
			},
		],
	},
	{
		path: '/pay',
		name: 'KiwoomPay',
		component: () => import('@/views/payment/Kiwoompay.vue'),
	},
	{
		path: '/kiwoom/popup',
		name: 'KiwoomPayPopup',
		component: () => import('@/components/payment/kiwoompay/PayRequest.vue'),
	},
	{
		path: '/kiwoom/integration/popup',
		name: 'KiwoomPayIntegrationApiPopup',
		component: () => import('@/components/payment/kiwoompay/IntegrationApiPayRequest.vue'),
	},
	{
		path: '/apply',
		name: 'Apply',
		component: () => import('@/views/Layout.vue'),
		redirect: { name: 'step1' },
		meta: { auth: true },
		children: [
			{
				path: 'step1',
				name: 'step1',
				beforeEnter: saveCurrentStep('apply'),
				component: () => import('@/views/apply/ApplyService.vue'),
			},
			{
				path: 'step1/free-direct',
				name: 'free-direct',
				beforeEnter: saveCurrentStep('apply'),
				component: () => import('@/views/apply/ApplyService.vue'),
			},
			{
				path: 'service/step1',
				name: 'ServiceStep1',
				beforeEnter: saveCurrentStep('apply'),
				component: () => import('@/views/apply/service/Step1.vue'),
			},
			{
				path: 'service/step2',
				name: 'ServiceStep2',
				beforeEnter: saveCurrentStep('apply'),
				component: () => import('@/views/apply/service/Step2.vue'),
			},
			{
				path: 'service/step3',
				name: 'ServiceStep3',
				beforeEnter: saveCurrentStep('apply'),
				component: () => import('@/views/apply/service/Step3.vue'),
			},
			{
				path: 'estimate/step1',
				name: 'EstimateStep1',
				beforeEnter: saveCurrentStep('apply'),
				component: () => import('@/views/apply/estimate/Step1.vue'),
			},
			{
				path: 'estimate/step2',
				name: 'EstimateStep2',
				beforeEnter: saveCurrentStep('apply'),
				component: () => import('@/views/apply/estimate/Step2.vue'),
			},
		],
	},
	{
		path: '/user',
		component: () => import('@/views/Layout.vue'),
		meta: { auth: true },
		children: [
			{
				path: 'myStore/MembershipStatus',
				name: 'MembershipStatus',
				meta: { upperCrumb: '멤버십', breadCrumb: '가입현황' },
				beforeEnter: saveCurrentStep('user'),
				component: () => import('@/views/user/myStore/MembershipStatusLayer.vue'),
			},
			{
				path: 'myStore/CompanyInfo',
				name: 'CompanyInfoMain',
				meta: { upperCrumb: '멤버십', breadCrumb: '회사정보' },
				beforeEnter: saveCurrentStep('user'),
				component: () => import('@/views/user/myStore/CompanyInfoMain.vue'),
			},
			{
				path: 'myStore/SiteStatus',
				name: 'SiteStatus',
				meta: { upperCrumb: '멤버십', breadCrumb: '사이트현황' },
				component: () => import('@/views/user/myStore/SiteStatusLayer.vue'),
			},
			{
				path: 'myStore/OrderHistory',
				name: 'OrderHistory',
				meta: { upperCrumb: '멤버십', breadCrumb: '주문/구매내역' },
				component: () => import('@/views/user/myStore/OrderHistory.vue'),
			},
			{
				path: 'myStore/OrderDetail/:id',
				name: 'OrderDetail',
				meta: { upperCrumb: '멤버십', breadCrumb: '주문/구매내역 상세' },
				component: () => import('@/views/user/myStore/OrderDetail.vue'),
			},
		],
	},
	{
		path: '/order',
		component: () => import('@/views/Layout.vue'),
		meta: { auth: true },
		redirect: { name: 'order/AddExtensionLayer' },
		children: [
			{
				path: 'addExtension',
				name: 'OrderAddExtension',
				meta: {
					upperCrumb: '스토어',
					breadCrumb: '서비스 추가·연장',
				},
				beforeEnter: saveCurrentStep('order'),
				component: () => import('@/views/order/AddExtensionLayer.vue'),
			},
			{
				path: 'addExtension/autoExtension',
				name: 'OrderAddExtensionAutoExtension',
				meta: {
					upperCrumb: '스토어',
					breadCrumb: '서비스 추가·연장',
				},
				component: () => import('@/views/order/AddExtensionLayer.vue'),
			},
			{
				path: 'purchase',
				name: 'OrderPurchase',
				meta: {
					upperCrumb: '스토어',
					breadCrumb: '서비스 추가·연장 구매하기',
				},
				beforeEnter: saveCurrentStep('order'),
				component: () => import('@/views/order/Purchase.vue'),
			},
			{
				path: 'complete',
				name: 'OrderComplete',
				meta: {
					upperCrumb: '스토어',
					breadCrumb: '서비스 추가·연장 구매완료',
				},
				beforeEnter: saveCurrentStep('order'),
				component: () => import('@/views/order/Complete.vue'),
			},
		],
	},
	{
		path: '*',
		component: () => import('@/views/common/NotFound.vue'),
	},
	{
		path: '/underconstruct',
		component: () => import('@/views/common/UnderConstruction.vue'),
	},
	{
		path: '/force/logout',
		name: 'ForceLogout',
		component: () => import('@/views/ForceLogout.vue'),
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/login/Login.vue'),
		props: true,
	},
	{
		path: '/logout',
		name: 'Logout',
		component: () => import('@/views/login/Logout.vue'),
		props: true,
	},
	{
		path: '/signup',
		name: 'Signup',
		component: () => import('@/views/login/Signup.vue'),
		props: true,
	},
	{
		path: '/identityVerificationSuccess',
		component: () => import('@/views/login/IdentityVerificationSuccess.vue'),
	},
	{
		path: '/oauth/redirect/login',
		component: () => import('@/components/login/LoginRedirect.vue'),
	},
	{
		path: '/oauth/redirect/signup',
		component: () => import('@/components/login/SignupRedirect.vue'),
	},
	{
		path: '/oauth/redirect/sns',
		component: () => import('@/components/user/AccountLinkRedirect.vue'),
	},
	{
		path: '/snsAccountFail',
		component: () => import('@/components/user/SnsAccountRedirect'),
	},
	{
		path: '/sso',
		component: () => import('@/views/login/LinkAccess'),
	},
	{
		path: '/invite',
		component: () => import('@/views/login/LinkAccess'),
	},
	{
		name: 'SignUpSuccess',
		path: '/success',
		component: () => import('@/components/login/SignUpSuccess'),
	},
	{
		name: 'SignUpFail',
		path: '/fail',
		component: () => import('@/components/login/SignUpFail'),
	},
	{
		name: 'AlreadyEmailVerification',
		path: '/already',
		component: () => import('@/components/login/SignUpAlready'),
	},
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
	return originalPush.call(this, location).catch((err) => {
		if (err.name !== 'NavigationDuplicated') throw err;
	});
};

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior: function (to, from, savedPosition) {
		const scrollBody = document.getElementById('scrollBody');
		if (scrollBody) {
			scrollBody.scrollTo(0, 0);
		}
		return savedPosition || { x: 0, y: 0 };
	},
});

const applyGoogleAnalyticsPaths = [
	'/login', // 로그인
	'/signup', // 회원가입
	'/home', // 홈
	'/user/myStore/CompanyInfo', // 법인정보 관리
	'/user/myStore/MembershipStatus', // 멤버십 현황
	'/user/myStore/OrderHistory', // 구매내역
	'/apply/step1', // 신규 주문
	'/order/addExtension', // 연장 주문
	'/user/myStore/SiteStatus', // 시스템 현황
	'/profile', // 내 정보 관리
];

router.beforeEach(async (to, from, next) => {
	if (to.matched.some((data) => data.meta.auth)) {
		store.commit('SET_IS_IN_ORDER_PROCESS', to.matched[0].path === '/apply' || to.matched[0].path === '/order');
		await store.dispatch('checkAuth');
		if (!store.getters.hasAuth) {
			next('/login');
			return;
		}
	}
	if (to.path != '/underconstruct' && window.__env.underConstruction.apply) {
		next({
			path: 'underconstruct',
			replace: true,
		});
	} else {
		// 페이지별로 Google Analytics 초기화
		if (applyGoogleAnalyticsPaths.includes(to.path)) {
			initializeGoogleAnalytics();
		}
		next();
	}
});
export default router;
