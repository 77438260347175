import VueCookies from 'vue-cookies';
import Vue from 'vue';

Vue.use(VueCookies);
VueCookies.config('14d');

export const saveAccessTokenToCookie = (accessToken) => {
	VueCookies.set('doms_access_token', accessToken);
};

export const getUserFromCookie = () => {
	return VueCookies.get('doms_user');
};

export const getAuthFromCookie = () => {
	return {
		access_token: VueCookies.get('doms_access_token'),
		refresh_token: VueCookies.get('doms_refresh_token'),
	};
};

export const deleteCookieList = (...valueList) => {
	for (const e of valueList) {
		deleteCookie(e);
	}
};

export const deleteCookie = (value) => {
	VueCookies.remove(value);
};

export const deleteSnsLoginCookies = () => {
	VueCookies.remove('account_id');
	VueCookies.remove('doms_access_token');
	VueCookies.remove('is_linked_account');
	VueCookies.remove('is_main_sns_id');
	VueCookies.remove('email');
	VueCookies.remove('profile');
	VueCookies.remove('name');
	VueCookies.remove('sns_id');
	VueCookies.remove('sns_type');
	VueCookies.remove('sns_ci');
	VueCookies.remove('sns_id_link');
	VueCookies.remove('sns_type_link');
	VueCookies.remove('sns_ci_link');
};

export const getOAuthResultCookie = () => {
	return {
		accessToken: VueCookies.get('doms_access_token'),
		isLinkedAccount: JSON.parse(VueCookies.get('is_linked_account')),
		isMainSnsId: JSON.parse(VueCookies.get('is_main_sns_id')),
		concurrentAccess: VueCookies.get('concurrent_access'),
		email: VueCookies.get('email'),
		profile: VueCookies.get('profile'),
		name: VueCookies.get('name'),
		snsType: VueCookies.get('sns_type'),
		snsId: VueCookies.get('sns_id'),
		snsCi: VueCookies.get('sns_ci'),
		snsTypeLink: VueCookies.get('sns_type_link'),
		snsIdLink: VueCookies.get('sns_id_link'),
		snsCiLink: VueCookies.get('sns_ci_link'),
	};
};

export const getDetermineOriginUri = () => {
	let determine_origin_uri = VueCookies.get('determine_origin_uri');
	VueCookies.remove('determine_origin_uri');
	return determine_origin_uri;
};

const codeCookie = {
	openloungeKey: 'openlounge-code',
	daouofficeKey: 'daouoffice-code',
	memberInviteKey: 'invite-code',
};

export const getLinkCodes = () => {
	return {
		inviteCode: VueCookies.get(codeCookie.memberInviteKey),
		daouofficeCode: VueCookies.get(codeCookie.daouofficeKey),
		openLoungeCode: VueCookies.get(codeCookie.openloungeKey),
	};
};

export const getInviteCodeCookie = () => {
	return VueCookies.get(codeCookie.memberInviteKey);
};

export const getDaouofficeCodeCookie = () => {
	return VueCookies.get(codeCookie.daouofficeKey);
};

export const getOpenLoungeeCode = () => {
	return VueCookies.get('openlounge-code');
};

export const removeInviteCodeCookie = () => {
	VueCookies.remove(codeCookie.memberInviteKey);
};

export const removeDaouofficeCodeCookie = () => {
	VueCookies.remove(codeCookie.daouofficeKey);
};

export const setInviteCodeCookie = (code) => {
	VueCookies.set(codeCookie.memberInviteKey, code);
};

export const setDaouofficeCodeCookie = (code) => {
	VueCookies.set(codeCookie.daouofficeKey, code);
};
