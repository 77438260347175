import { domsAPI, paymentAPI, portalAPI, portalAuthAPI } from './index';
import { byteToGigaByte, gigaByteToByte } from '@/utils/utils';

// 파트너사 조회
export const getPartner = async (code) => {
	return await domsAPI.get(`/paysystem/partner/${code}`);
};

// 멤버십조회(가입현황)
export const getMembership = async (id) => {
	return await portalAuthAPI.get(`/membership/${id}`, {
		timeout: 1000,
	});
};

// 고객 정보 수정 (manager 수정 시 사용)
export const updateCustomer = async (data) => {
	return await portalAuthAPI.put(`/customer`, data);
};

// 잔여일 조회 (추가 시 필요)
export const getRemainDays = async (data) => {
	return await portalAuthAPI.get(`/membership/${data.membershipId}/remaindate?startDate=${data.startDate}`);
};

export const getOrderList = async (data) => {
	return await portalAuthAPI.get(`/membership/${data.membershipId}/orders`, {
		params: data,
	});
};

export const getOrder = async (orderId) => {
	return await portalAuthAPI.get(`/order/${orderId}`);
};

export const cancleOrder = async (orderId) => {
	return await portalAuthAPI.put(`/order/drop/${orderId}`);
};

// 도메인 중복 체크
export const checkdomain = async (domain) => {
	return await domsAPI.get(`/public/checkdomain?domain=${domain}`);
};

// url 중복 체크
export const checkurl = async (url) => {
	return await domsAPI.get(`/public/checkurl?url=${url}`);
};

//멀티컴퍼니 확인
export const checkMultiCompany = (data) => {
	return domsAPI.get('/public/checkmulticompany', { params: data });
};

// 멤버십 사용량 조회
export const getCompanyUsage = async (id) => {
	return await portalAuthAPI.get(`/membership/${id}/usage`);
};

// 경리회계 옵션상품 사용량 조회
export const getAccountUsage = async (id) => {
	return await portalAuthAPI.get(`/membership/${id}/usage/account`);
};

export const changePayType = async (data) => {
	return await domsAPI.put(`/paysystem/order/paytype`, data);
};
// 자동결제 카드 등록
export const saveAutoPaymentCardInfo = async (data) => {
	return await paymentAPI.post(`/autoPayment/card/save`, data);
};
// 자동결제 취소
export const cancelAutoPaymentCardInfo = (membershipId) => {
	paymentAPI.delete(`/autoPayment/cancel/${membershipId}`);
};

// 자동결제 카드 조회
export const searchAutoPaymentCardInfo = async (customerId) => {
	return await paymentAPI.get(`/autoPayment/card/${customerId}`);
};
export const setAutoExtension = async (data) => {
	// 신용카드 자동연장 연결
	return await paymentAPI.post(`/kiwoom/batch`, data);
};

const requestMap = {};
export const userSso = async (token = sessionStorage.getItem('userToken')) => {
	if (requestMap['sso']) return requestMap['sso'];
	let promise;
	if (token) {
		const ssoRequest = paymentAPI.get(`/user/sso?token=${token}`);
		requestMap['sso'] = ssoRequest;
		promise = await ssoRequest;
		delete requestMap['sso'];
	} else {
		promise = await paymentAPI.get(`/user/session`);
	}
	return promise;
};

export const getAdditionalOptions = async (data) => {
	return await domsAPI.get(`/paysystem/enum/constants/${data.type}`);
};

export const fetchSiteStatus = async (membershipId) => {
	const resp = await portalAuthAPI.get(`/membership/${membershipId}/resource`);
	let siteStatus = {};
	if (resp.data) {
		siteStatus = resp.data;
		siteStatus.usedQuota = byteToGigaByte(siteStatus.usedQuota);
		siteStatus.purchaseQuota = byteToGigaByte(siteStatus.purchaseQuota);
		siteStatus.purchaseAccountQuota = byteToGigaByte(siteStatus.purchaseAccountQuota);
		siteStatus.purchaseCompanyQuota = byteToGigaByte(siteStatus.purchaseCompanyQuota);
		siteStatus.sites.forEach((site) => {
			site.usedAccountQuota = byteToGigaByte(site.usedAccountQuota);
			site.usedCompanyQuota = byteToGigaByte(site.usedCompanyQuota);
			site.purchaseAccountQuota = byteToGigaByte(site.purchaseAccountQuota);
			site.purchaseCompanyQuota = byteToGigaByte(site.purchaseCompanyQuota);
		});
	}

	return siteStatus;
};

export const setSiteStatus = async (sites, membershipId) => {
	sites.forEach((site) => {
		site.membershipId = membershipId;
		site.domainName = site.domain;
		site.companyName = site.siteName;
		site.siteUrl = site.url;
		site.managerName = site.adminName;
		site.userCount = site.purchaseUserCount;
		site.companyCreditCardCount = site.purchaseCompanyCreditCardCount;
		site.personalCardCount = site.purchasePersonalCardCount;
		site.taxBillCount = site.purchaseTaxBillCount;
		site.bankAccountCount = site.purchaseBankAccountCount;
		site.meetingRoomCount = site.purchaseMeetingRoomCount;
		site.companyQuota = gigaByteToByte(site.purchaseCompanyQuota);
		site.totalAccountQuota = gigaByteToByte(site.purchaseAccountQuota);
	});
	return await domsAPI.put(`/paysystem/company/resource/update/${membershipId}`, sites);
};

export const createSite = async (data) => {
	const requestModel = {
		membershipId: data.membershipId,
		companyId: null,
		uuid: null,
		domainName: data.site.domain,
		companyName: data.site.siteName,
		siteUrl: data.site.url,
		managerId: data.site.adminId,
		managerName: data.site.adminName,
		managerPassword: data.site.password,
		managerPasswordConfirm: data.site.passwordConfirm,
		userCount: data.site.purchaseUserCount,
		companyQuota: gigaByteToByte(data.site.purchaseCompanyQuota),
		totalAccountQuota: gigaByteToByte(data.site.purchaseAccountQuota),
		meetingRoomCount: data.site.purchaseMeetingRoomCount,
		companyCreditCardCount: data.site.purchaseCompanyCreditCardCount,
		personalCardCount: data.site.purchasePersonalCardCount,
		taxBillCount: data.site.purchaseTaxBillCount,
		bankAccountCount: data.site.purchaseBankAccountCount,
	};
	return await domsAPI.post(`/paysystem/company/create`, requestModel);
};

export const updateSite = async (data) => {
	const requestModel = {
		membershipId: data.membershipId,
		companyId: data.site.companyId,
		uuid: data.uuid,
		domainName: data.site.domain,
		companyName: data.site.siteName,
		siteUrl: data.site.url,
		managerName: data.site.adminName,
		userCount: data.site.purchaseUserCount,
		companyQuota: gigaByteToByte(data.site.purchaseCompanyQuota),
		totalAccountQuota: gigaByteToByte(data.site.purchaseAccountQuota),
		meetingRoomCount: data.site.purchaseMeetingRoomCount,
		companyCreditCardCount: data.site.purchaseCompanyCreditCardCount,
		personalCardCount: data.site.purchasePersonalCardCount,
		taxBillCount: data.site.purchaseTaxBillCount,
		bankAccountCount: data.site.purchaseBankAccountCount,
	};
	return await domsAPI.put(`/paysystem/company/update/${data.membershipId}/${data.uuid}`, requestModel);
};

export const deleteSite = async (data) => {
	return await domsAPI.delete(`/paysystem/company/delete/${data.membershipId}/${data.uuid}`);
};

// 경리회계 ON/OFF
export const putAccountOnOff = async (data) => {
	return await domsAPI.put(`/addon/switch/${data.id}/${data.uuid}`, data.params);
};

export const deleteUser = async (data, config) => {
	return await portalAuthAPI.delete(`/users/${data.id}`, config);
};

export const fetchCurrentUser = async () => {
	return await portalAuthAPI.get(`/users/me`);
};

export const fetchUserTerms = async () => {
	return await portalAuthAPI.get(`/users/me/terms`);
};

export const updateCurrentUser = async (data, config) => {
	return await portalAuthAPI.put(`/users/me`, JSON.stringify(data), config);
};

export const newSnsLogin = async (data, config) => {
	return await portalAuthAPI.post(`/users/me/sns`, JSON.stringify(data), config);
};

export const deleteSnsLogin = async (data, config) => {
	return await portalAuthAPI.delete(`/users/me/sns/${data.id}`, config);
};

export const updateProfile = async (data, config) => {
	return await portalAuthAPI.put(`/users/me/profile`, data, config);
};

export const deleteProfile = async (data, config) => {
	return await portalAuthAPI.delete(`/users/me/profile`, config);
};

export const fetchAgreement = async (data, config) => {
	return await portalAuthAPI.get(`/users/me/agreement`, config);
};

export const fetchUser = async (data, config) => {
	return await portalAuthAPI.get(`/users/${data.id}`, config);
};

export const createUser = async (data, config) => {
	return await portalAuthAPI.post(`/users`, JSON.stringify(data.params), config);
};

export const updateUser = async (data, config) => {
	return await portalAuthAPI.put(`/users/${data.id}`, JSON.stringify(data.params), config);
};

export const updateUserIdentity = async (data, config) => {
	return await portalAuthAPI.put(`/users/me/identity`, JSON.stringify(data), config);
};

export const updateCustomerManagerUserIdentity = async (data, config) => {
	return await portalAuthAPI.put(`/users/me/identity/manager`, JSON.stringify(data), config);
};

export const updateUserTwoFactor = async (data, config) => {
	return await portalAuthAPI.put(`/auth/twofactor`, JSON.stringify(data), config);
};

export const updateUserExtra = async (data, config) => {
	return await portalAuthAPI.put(`/users/me/extra`, JSON.stringify(data), config);
};

export const sendMailUpdateUserEmail = async (data, config) => {
	return await portalAuthAPI.post(`/auth/email`, JSON.stringify(data), config);
};

export const updateUserEmail = async (data, config) => {
	return await portalAuthAPI.put(`/auth/email`, JSON.stringify(data), config);
};

export const updateUserTerms = async (data, config) => {
	return await portalAuthAPI.put(`/users/me/terms`, JSON.stringify(data), config);
};

export const getMemberships = async (data, config) => {
	return await portalAuthAPI.get('/membership');
};

// 경리회계 관리자 ID 변경
export const changeAddonAdmin = async (data) => {
	return await domsAPI.put(`/addon/account/admin`, data);
};

export const linkOpenlounge = async (data) => {
	return await portalAuthAPI.post(`/openlounge/link`, data);
};

export const linkDaouOffice = async (data) => {
	return await portalAuthAPI.post(`/daouoffice/link`, data);
};

export const ssoDaouOffice = async (data) => {
	return await portalAPI.post(`/daouoffice/public/sso`, data);
};

export const sendCustomerAccountInviteEmail = async (data) => {
	return await portalAPI.post('/public/daouoffice/customer/users/apply', data);
};
