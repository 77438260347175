export const ADD_ACCOUNTING = `
<p class="txt">경리회계 개설을 위한 신청서를 작성해주시면 저희가 경리회계를 개설해드립니다!</p>
<br />
<div class="wrap_btn_box">
    <a href="https://daouoffice.co.kr/account_consult.jsp" target="_blank">
        <div class="btn_box">
            <button class="solid">경리회계 신청서 작성하기</button>
        </div>
    </a>
</div>
`;

export const ON_ACCOUNTING = `
<p class="txt_caution">메뉴를 ON 하시겠습니까?</p>
<p class="txt">모든 임직원의 웹, 모바일의 채널 메뉴에 경리회계(경영지원)이 노출됩니다.<br>
 경리회계 ON에 따라 인사정보는 경영지원을 통해서만 이용 가능합니다.</p>
`;

export const HAS_ACCOUNT_PAID = `<p class="txt">사용 중인 유료 상품이 있습니다.<br>환불 요청은 1577-3019로 문의주시기 바랍니다.</p>`;

export const OFF_ACCOUNTING = `
<p class="txt_caution">메뉴를 OFF 하시겠습니까?</p>
<p class="txt">모든 임직원의 채널 메뉴에서 경리회계(경영지원)은 노출되지 않습니다.<br>
경리회계 OFF에 따라 인사정보는 다우오피스를 통해서만 이용 가능합니다.</p>
`;

export const CHECK_EMAIL = (email) => {
	return `
        <p class="body-2-r mb-24">
            회원정보에 등록한 이메일 주소를 확인해주세요. </br>
            해당 메일로 임시 비밀번호를 전송합니다. </p>
        <div class="group-inp block disabled">
            <input type="text" class="" placeholder="" value="${email}" disabled/>
        </div>
    `;
};

export const CHECK_LOGINID_EMAIL_CREATED_AT = (loginId, email, createdAt) => {
	return `
        <p class="body-2-r mb-24">
            회원정보에 등록한 이메일 주소를 확인해주세요.<br>
            해당 메일로 임시 비밀번호를 전송합니다.
        </p>
        <div class="highlight">
            <div class="mb-16">
                <p class="body-2-r mb-8">아이디</p>
                <p class="subheading-b">${loginId}</p>
            </div>
            <div class="mb-16">
                <p class="body-2-r mb-8">이메일</p>
                <p class="subheading-b">${email}</p>
            </div>
            <div class="mb-16">
                <p class="body-2-r mb-8">가입일자</p>
                <p class="subheading-b">${createdAt}</p>
            </div>
        </div>
    `;
};

export const ALREADY_SIGNED = (name, email, loginId) => {
	return `
        <p class="body-2-r mb-24">
            <strong>${name}</strong> 님, <br />
            이미 다우오피스 고객포털에 가입되어 있습니다. <br />
            아래의 아이디로 로그인 후 이용해 주세요.</p>
        <div class="highlight"><div>
            <div class="mb-16">
                <p class="body-2-r mb-8">아이디</p>
                <p class="subheading-b">${loginId}</p>
            </div>
            <div class="mb-16">
                <p class="body-2-r mb-8">이메일</p>
                <p class="subheading-b">${email}</p>
            </div></div>
        </div>`;
};

export const NOT_SIGNED = `
<div class="text-center">
    <div class="popup-graphic"><i class="ic-64 ic-noti"></i></div>
    <h3>가입 정보가 없습니다.<h3>
</div>
<br/>
<div class="text-center">고객포털에 이미 회원가입을 하신 경우라면 가입하신 계정으로 로그인 후 <b>[내 정보 관리]</b>에서 다우오피스 계정 혹은 소셜 계정으로 <b>추가 계정 연동</b>을 하시면 계정/비밀번호 입력 절차 없이 로그인이 가능합니다.</div>
`;

export const DIFF_IDENTITY = `
<div class="text-center">
    <div class="popup-graphic"><i class="ic-64 ic-noti"></i></div>
    <h3>본인인증 결과가 달라 변경이 불가능합니다.<h3>
</div>
<br/>
<div class="text-center">회원가입한 사용자와 동일한 사용자로 인증해주세요.</div>
`;

export const NOT_MATCH_EMAIL_AND_IDENTITY = `
<div class="text-center">본인인증 결과가 가입한 회원과 불일치합니다. </br> 가입한 회원으로 본인인증을 시도해주세요</div>
`;

export const DISAGREE_TERMS = `
<div class="text-center">약관 동의를 철회하시겠습니까?</div>`;

export const OTHER_CUSTOMER_SUPER_ADMIN = `
<div class="text-center">
    <div class="popup-graphic"><i class="ic-64 ic-noti"></i></div>
    <h3>다른 고객사의 슈퍼 계정입니다.<h3>
</div>
<br/>
<div class="text-center">사용자의 권한을 수정하시려면 <b>[사용자관리]</b>에서 다른 사용자에게 슈퍼 계정 권한을 설정해주시기 바랍니다.</div>
`;

export const ALREADY_USED_EMAIL = `이미 가입되어 있는 이메일 주소입니다.`;

export const VALID_EMAIL = `사용 가능한 이메일 주소입니다.`;

export const INVALID_EMAIL = `올바르지 않은 이메일 형식입니다.`;

export const NOT_ALLOW_SIGNUP_EMAIL = '해당 이메일로 회원가입할 수 없습니다. 다시 입력부탁드립니다.';

export const INVALID_PASSWORD_LENGTH_CHARACTER = `비밀번호는 8-16자의 영문자, 숫자, 특수문자를 사용하세요.`;

export const INVALID_PASSWORD_CONTENT = `아이디, 이메일, 생년월일, 전화번호, 연속되는 숫자와 문자 등은 비밀번호로 사용하실 수 없습니다.`;

export const VALID_PASSWORD = `사용 가능한 비밀번호 입니다.`;

export const OLD_PASSWORD_FAIL = `기존 비밀번호를 확인해주세요.`;

export const RECENT_THREE_OLD_PASSWORD = `최근에 사용했던 비밀번호는 사용할 수 없습니다.`;

export const PASSWORD_MATCH_FAIL = `비밀번호가 불일치합니다. 확인해주세요.`;

export const PASSWORD_MATCH = `비밀번호가 일치합니다.`;

export const SNS_ACCOUNT_CI_NOT_EXIST =
	'SNS에서 실명인증이 되지 않아 해당 계정으로 인증처리를 진행할 수 없습니다. </br> 다른 계정으로 시도하거나 고객포탈로 회원가입해주세요.';

export const SNS_LINK_CI_NOT_EXIST =
	'SNS에서 실명인증이 되지 않아 해당 계정으로 인증처리를 진행할 수 없습니다. </br> 다른 계정으로 시도해주세요.';

export const SEND_EMAIL_WAITING = '이미 전송된 인증 메일이 있습니다. </br> 잠시 후 다시 시도해 주세요.';

export const SECESSION_COMPLETE =
	'회원탈퇴가 완료되었습니다.<br/> 그동안 다우오피스 고객포털을 이용해 주셔서 감사합니다.';

export const DOMAIN_BUYING_GUIDE = `
    <div class="section-wrap gap20">
        <!-- 01 -->
        <div class="section">
            <!-- title -->
            <h2 class="body-2-m text-primary mb-4">도메인을 왜 사용해야 하나요?</h2>
            <!-- cont -->
                <p class="body-2-r">다우오피스 메일을 이용하기 위해서는 별도 회사 도메인을 가지고 있어야 하기 때문에 도메인이 없을 경우 별도로 구매를 해주셔야 합니다.</p>
                <p class="body-2-r">*도메인명은 구성원의 아이디와 함께 '아이디@도메인명' 형식의 계정과 이메일 주소를 사용합니다.</p>
        </div>
        <!-- 02 -->
        <div class="section">
            <!-- title -->
            <h2 class="body-2-m text-primary mb-4">도메인을 갖고 있으면 어떤게 좋은가요?</h2>
            <!-- cont -->
            <!-- sub 01 -->
            <p class="body-2-r">1. 기업이미지 구축</p>
            <div class="ml-16  mb-4">
                <p class="body-2-r">&#183; 기업 사명이 들어간 고유의 도메인 주소를 사용하면 기업의 정체성 UP!</p>
                <p class="body-2-r">&#183; 주소를 통해 사명이 드러나므로 자연스러운 각인 효과 및 브랜드 홍보 효과까지!</p>
            </div>
            <!-- sub 02 -->
            <p class="body-2-r">2. 협업 및 업무 특화형 기능</p>
            <div class="ml-16  mb-4">
                <p class="body-2-r">&#183; 기업메일 서비스만의 다양한 업무 특화형 기능으로 업무 효율성 UP!</p>
            </div>
            <!-- sub 03 -->
            <p class="body-2-r">3. 보안 및 장애관리</p>
            <div class="ml-16  mb-4">
                <p class="body-2-r">&#183; 일반메일 사용시 보안 및 장애관리가 보장되지 않는 문제점 해결!</p>
            </div>
        </div>
        <!-- 03 -->
        <div class="section group column gap4">
            <!-- title -->
            <h2 class="body-2-m text-primary mb-4">도메인 구매 방법</h2>
            <!-- cont -->
            <!-- sub 01 -->
            <div class="group row gap8">
                <p class="body-2-r">1. 도메인을 구매할 사이트에 접속한다 </p>
                <a class="body-3-m text-bk" href="https://www.halfdomain.co.kr/index" target="_blank" >반값도메인 구매 바로가기</a>    
            </div>
            <!-- sub 02 -->
            <p class="body-2-r">2. 원하는 도메인 명을 검색한다.</p>
            <div class="guide-img img-01">도메인검색</div>
                <!-- sub 03 -->
            <p class="body-2-r">3. 등록가능한 도메인을 선택하여 결제한다.</p>
            <div class="guide-img img-02">도메인선택</div>
            <p class="">* 구매한 도메인의 메일 사용 방법은 하단의 설정가이드 페이지를 참고해주세요!</p>
            <div class="guide_link">
                <a class="body-3-m text-bk ml-8" href="https://care.daouoffice.co.kr/hc/ko/articles/360021018953" target="popup" >도메인 메일 설정가이드 바로가기</a>
            </div>
        </div>
    </div>
`;

export const SNS_ALREADY_SIGNUP_TRY_ANOTHER = `다른 사용자로 회원가입하거나 기존에 가입하신 계정으로 로그인 후 <br/> <b>[내 정보 관리]</b>에서 <b>추가 계정 연동</b>을 하시면 계정/비밀번호 입력 절차 없이 로그인이 가능합니다.`;

export const SNS_ALREADY_SIGNUP_TRY_LOGIN = `이미 다우오피스 고객포털에 가입되어 있습니다. <br />기존에 가입하신 계정으로 로그인 후 이용해 주세요 <br />[내 정보 관리]에서 추가 계정 연동을 하시면 계정/비밀번호 입력 절차 없이 로그인이 가능합니다.`;

export const VALID_LOGINID = `사용 가능한 아이디입니다.`;

export const ALREADY_USED_LOGINID = `이미 가입되어 있는 아이디입니다.`;

export const INVALID_LOGINID = '아이디는 3자 이상, 64자 이하의 영문(소문자), 숫자, -, _, . 을 사용하세요.';

export const PURCHASE_SEND_EMAIL_PHONE = `<p class="description mt-8 txt g600">* 주문 담당자(주문자) 연락처와 이메일로 결제 및 주문 완료 알림이 발송됩니다.</p>`;

export const INVALID_SITENAME_LENGTH = '회사명(사이트명)은 2자 이상, 64자 이하를 입력해야 합니다.'; // error

export const INVALID_SITENAME_REQUIRE = '회사명(사이트명)은 필수 입력 항목입니다.'; // empty

export const INVALID_SITENAME_CHARACTER =
	'회사명(사이트명)에 |, \\, ", #, $, <, >, ;, , 등의 특수문자는 사용 불가합니다.'; // invalid

export const INVALID_MASTERID_LENGTH_CHARACTER =
	'관리자 ID는 3자 이상, 64자 이하의 영문(소문자), 숫자, -, _, . 만 입력해야 합니다.'; // error

export const INVALID_MASTERID_REQUIRE = '관리자 ID는 필수 입력 항목입니다.'; // empty

export const VALID_DOMAIN = '사용할 수 있는 도메인입니다.'; //valid

export const INVALID_DOMAIN_LENGTH_CHARACTER =
	'메일 도메인은 3자 이상, 64자 이하의 영문, 숫자, -, . 만 입력해야 합니다.'; // error

export const INVALID_DOMAIN_REQUIRE = '메일 도메인은 필수 입력 항목입니다.'; // empty

export const DOMAIN_NEEDCONFIRM = '입력하신 메일 도메인에 대한 [확인]이 필요합니다.'; //needConfirm

export const VALID_URL = '사용할 수 있는 주소입니다.'; //valid

export const ALREADY_EXIST_URL = '이미 사용중인 접속주소입니다';

export const INVALID_URL_LENGTH_CHARACTER = '접속 URL은 1자 이상, 64자 이하의 영문, 숫자, - 만 입력해야 합니다.'; //error

export const INVALID_URL_REQUIRE = '접속 URL은 필수 입력 항목입니다.'; // empty

export const URL_NEEDCONFIRM = '입력하신 접속 URL에 대한 [확인]이 필요합니다.'; // needConfirm

export const AUTHENTICATION_CONFIRM_SUCCESS = '본인인증을 완료했습니다.';

export const AUTHENTICATION_CONFIRM_FAIL = '본인인증이 필요합니다.';

export const REQUIRED_CHECKED_TERM_FAIL = '필수 약관에 동의가 필요합니다.';

export const INVALID_BUSINESSLICENSENUM = '사업자 정보를 확인할 수 없습니다. 사업자등록번호를 정확하게 입력해주세요.';

export const DUPLICATE_BUSINESSLICENSENUM = '기존 다우오피스 고객입니다. 영업 담당자에게 문의하여 초대장을 요청하세요.';

export const NEED_TO_CONFIRM_BUSINESSLICENSENUM = '사업자 등록번호 확인이 필요합니다.';

export const EMPTY_INPUT = '필수 입력값입니다';

export const VALID_NAME = '가능한 이름입니다.';
