import {
	getContractInfoByCode,
	createNewOrderInfo,
	applyNewOrderInfo,
	sendNewOrderEstimate,
	confirmAdminId,
	getMembershipPaymentDetail,
	getMembershipBaseChannelChanged,
	calculateItemPrice,
	getConversionMembership,
} from '@/api/order';

import { getBaseChannelAddExtensionOptionItemList, getAddonChannelExtensionOptionItemList } from '@/api/product';

const initState = {
	orderTargetCustomer: {},
	newOrder: {},
};
export default {
	state: { ...initState },
	getters: {},
	actions: {
		async fetchContractInfo({ commit }, params) {
			// 계약 정보 조회 (params : 계약코드(주문코드+'-CONT'))
			const { data } = await getContractInfoByCode(params);
			return data;
		},
		async createNewOrderInfo({ commit }, params) {
			const { data } = await createNewOrderInfo(params);
			return data;
		},
		async applyNewOrderInfo({ commit }, params) {
			await applyNewOrderInfo(params);
		},
		async sendNewOrderEstimate({ commit }, params) {
			const { data } = await sendNewOrderEstimate(params);
			return data;
		},
		async fetchBaseChannelAddExtensionOptionItemList({ commit }, params) {
			const { data } = await getBaseChannelAddExtensionOptionItemList(params);
			return data;
		},
		async fetchAddonChannelExtensionOptionItemList({ commit }, params) {
			const { data } = await getAddonChannelExtensionOptionItemList(params);
			return data;
		},
		async confirmAdminId({ commit }, params) {
			const { data } = await confirmAdminId(params);
			return data;
		},
		async fetchMembershipPaymentDetail({ commit }, params) {
			const { data } = await getMembershipPaymentDetail(params);
			return data;
		},

		async fetchMembershipBaseChannelChanged({ commit }, params) {
			const { data } = await getMembershipBaseChannelChanged(params);
			return data;
		},
		async CALCULATE_ITEM_PRICE({ commit }, params) {
			const { data } = await calculateItemPrice(params);
			return data;
		},
		async fetchConversionMembership({ commit }, params) {
			const { data } = await getConversionMembership(params);
			return data.data;
		},
	},
	mutations: {
		INITIALIZE_ORDER(state, value) {
			const keys = Object.keys(initState);
			keys.map((key) => {
				state[key] = initState[key];
			});
		},
	},
};
