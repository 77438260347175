import { domsAPI, attachAPI } from './index';

export const fileUpload = async (formData) => {
	return await attachAPI.post(`/attachment`, formData);
};
export const fileApply = async (data) => {
	return await attachAPI.put(`/attachment`, data);
};

export const checkBusinessLicenseNumber = async (data) => {
	return await domsAPI.get('/public/customer/license/validation', {
		params: data,
	});
};
