import Vue from 'vue';
import ToastComponent from '@/components/code/modal/ToastComponent.vue';

export const show = (text) => {
	new Vue({
		created() {
			this.$modal.show(
				ToastComponent,
				{
					toastText: text,
				},
				{
					width: '480px',
					height: '48px',
					draggable: true,
				},
			);
		},
	});
};
